// used https://csvjson.com/csv2json to convert the spreadsheet's csv to json
// https://docs.google.com/spreadsheets/d/1axZbR_s87RCK3OzaE5KFQp8mSDOr15cZEOiWdbBWGRw/edit#gid=0

const sessions2021 = [
    {
      ID: 2520,
      Program: 'Aerospace',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/11/2021',
      'End Date': '6/19/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/5de36999570b2867b87a7ab5a74e2484cf667429/AU2021/2520',
      'Hash for Session Meta': '5de36999570b2867b87a7ab5a74e2484cf667429',
    },
    {
      ID: 2521,
      Program: 'Aerospace',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/031cbca0a17d941f8ecc7e0f0bb875a7fd41305a/AU2021/2521',
      'Hash for Session Meta': '031cbca0a17d941f8ecc7e0f0bb875a7fd41305a',
    },
    {
      ID: 2522,
      Program: 'Aerospace',
      'Session#': 3,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/2e8b84a828f7947aa5a3baf76e41ad8e38fdbf6a/AU2021/2522',
      'Hash for Session Meta': '2e8b84a828f7947aa5a3baf76e41ad8e38fdbf6a',
    },
    {
      ID: 2523,
      Program: 'Aerospace',
      'Session#': 4,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/bf6848b9a0e1f116e62679d7878a5676df432a9d/AU2021/2523',
      'Hash for Session Meta': 'bf6848b9a0e1f116e62679d7878a5676df432a9d',
    },
    {
      ID: 2524,
      Program: 'Aerospace',
      'Session#': 5,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/28/2021',
      'End Date': '8/5/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/d6e3864e31c373b2b1de6f9aefca4ebbf9004811/AU2021/2524',
      'Hash for Session Meta': 'd6e3864e31c373b2b1de6f9aefca4ebbf9004811',
    },
    {
      ID: 2725,
      Program: 'Architecture',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/87aa4f56a3fbc2c10b25e529436a8ec6f6638ce6/AU2021/2725',
      'Hash for Session Meta': '87aa4f56a3fbc2c10b25e529436a8ec6f6638ce6',
    },
    {
      ID: 2726,
      Program: 'Architecture',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/7a48663757ed5e972d7ee25799986da37009858f/AU2021/2726',
      'Hash for Session Meta': '7a48663757ed5e972d7ee25799986da37009858f',
    },
    {
      ID: 2525,
      Program: 'Biotechnology',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/11/2021',
      'End Date': '6/19/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/172723fcc8c3666f5b1b055856285e140889624a/AU2021/2525',
      'Hash for Session Meta': '172723fcc8c3666f5b1b055856285e140889624a',
    },
    {
      ID: 2526,
      Program: 'Biotechnology',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/002e8c37e2d09b5ac98302f1ac8a89ce12693d51/AU2021/2526',
      'Hash for Session Meta': '002e8c37e2d09b5ac98302f1ac8a89ce12693d51',
    },
    {
      ID: 2527,
      Program: 'Film, Journalism & Media Arts',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/11/2021',
      'End Date': '6/19/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/ea1d56115440bb223cd0dce4b839394145ba9261/AU2021/2527',
      'Hash for Session Meta': 'ea1d56115440bb223cd0dce4b839394145ba9261',
    },
    {
      ID: 2528,
      Program: 'Film, Journalism & Media Arts',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/c7482f447b258e24290a3ab4424cfbcda0dbf1a4/AU2021/2528',
      'Hash for Session Meta': 'c7482f447b258e24290a3ab4424cfbcda0dbf1a4',
    },
    {
      ID: 2727,
      Program: 'Film, Journalism & Media Arts',
      'Session#': 3,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/28/2021',
      'End Date': '8/5/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/7d938b5c2fa3cf8ba47a571feceab7845bc51af3/AU2021/2727',
      'Hash for Session Meta': '7d938b5c2fa3cf8ba47a571feceab7845bc51af3',
    },
    {
      ID: 2529,
      Program: 'Cybersecurity',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/11/2021',
      'End Date': '6/19/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/5edbd0cbaad704860d4849538923ea29ad4860ab/AU2021/2529',
      'Hash for Session Meta': '5edbd0cbaad704860d4849538923ea29ad4860ab',
    },
    {
      ID: 2530,
      Program: 'Cybersecurity',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/cf928dbbc7f95e0ea2aec74a6029766e478307c7/AU2021/2530',
      'Hash for Session Meta': 'cf928dbbc7f95e0ea2aec74a6029766e478307c7',
    },
    {
      ID: 2531,
      Program: 'Cybersecurity',
      'Session#': 3,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/96490f232c222ef79bb60bfe80e0cbce3d91c964/AU2021/2531',
      'Hash for Session Meta': '96490f232c222ef79bb60bfe80e0cbce3d91c964',
    },
    {
      ID: 2532,
      Program: 'Engineering',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/77b71b57e113f3f3e7ec4b26c93de5e29ff63aa7/AU2021/2532',
      'Hash for Session Meta': '77b71b57e113f3f3e7ec4b26c93de5e29ff63aa7',
    },
    {
      ID: 2533,
      Program: 'Engineering',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/28/2021',
      'End Date': '8/5/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/4b2fed0d8c121cff76e5d543454e136fc5a70eba/AU2021/2533',
      'Hash for Session Meta': '4b2fed0d8c121cff76e5d543454e136fc5a70eba',
    },
    {
      ID: 2728,
      Program: 'Engineering Intensive',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '8/3/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/29681ccd8314664978eef3e945586303065008ca/AU2021/2728',
      'Hash for Session Meta': '29681ccd8314664978eef3e945586303065008ca',
    },
    {
      ID: 2534,
      Program: 'Film Intensive',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/11/2021',
      'End Date': '6/28/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/810cb138092db9ce471c7a9fbec3e9ea4e07490d/AU2021/2534',
      'Hash for Session Meta': '810cb138092db9ce471c7a9fbec3e9ea4e07490d',
    },
    {
      ID: 2535,
      Program: 'Forensic Science',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/11/2021',
      'End Date': '6/19/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/ce211ad0f34b03ab147f1dc76e63f97b6c89fd20/AU2021/2535',
      'Hash for Session Meta': 'ce211ad0f34b03ab147f1dc76e63f97b6c89fd20',
    },
    {
      ID: 2536,
      Program: 'Forensic Science',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/323d471316d33d1327c7fd19b42ec58842704fac/AU2021/2536',
      'Hash for Session Meta': '323d471316d33d1327c7fd19b42ec58842704fac',
    },
    {
      ID: 2537,
      Program: 'Forensic Science',
      'Session#': 3,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/2224b423a6101a0f3fff3000ee10b36bdfb38d1e/AU2021/2537',
      'Hash for Session Meta': '2224b423a6101a0f3fff3000ee10b36bdfb38d1e',
    },
    {
      ID: 2538,
      Program: 'Forensic Science',
      'Session#': 4,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/2e3d893747013f3175eb7c8d9103fc850817b5a4/AU2021/2538',
      'Hash for Session Meta': '2e3d893747013f3175eb7c8d9103fc850817b5a4',
    },
    {
      ID: 2539,
      Program: 'Forensic Science',
      'Session#': 5,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/28/2021',
      'End Date': '8/5/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/7a8eaf213826ae6c004a456f18b4129264931246/AU2021/2539',
      'Hash for Session Meta': '7a8eaf213826ae6c004a456f18b4129264931246',
    },
    {
      ID: 2540,
      Program: 'Medicine & Health Care',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/11/2021',
      'End Date': '6/19/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/0cace3e022775bc23a8fa771ee842481922f69f4/AU2021/2540',
      'Hash for Session Meta': '0cace3e022775bc23a8fa771ee842481922f69f4',
    },
    {
      ID: 2541,
      Program: 'Medicine & Health Care',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/2a08facc63ea2b79c3bb798bb07f705a6380cdbd/AU2021/2541',
      'Hash for Session Meta': '2a08facc63ea2b79c3bb798bb07f705a6380cdbd',
    },
    {
      ID: 2542,
      Program: 'Medicine & Health Care',
      'Session#': 3,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/88361982489d934b8ae6b6fdb4fc2341bc9d05f0/AU2021/2542',
      'Hash for Session Meta': '88361982489d934b8ae6b6fdb4fc2341bc9d05f0',
    },
    {
      ID: 2543,
      Program: 'Medicine & Health Care',
      'Session#': 4,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/e5e4fc466fd35a1bd5f4654e095884a008c4e980/AU2021/2543',
      'Hash for Session Meta': 'e5e4fc466fd35a1bd5f4654e095884a008c4e980',
    },
    {
      ID: 2544,
      Program: 'Medicine & Health Care',
      'Session#': '5-A',
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/28/2021',
      'End Date': '8/5/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/07f005bc54c9d44c5cea995c774c82f43e6366b8/AU2021/2544',
      'Hash for Session Meta': '07f005bc54c9d44c5cea995c774c82f43e6366b8',
    },
    {
      ID: 2740,
      Program: 'Medicine & Health Care',
      'Session#': '5-B',
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/28/2021',
      'End Date': '8/5/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/9a6e72bf023009d46e505c61e94bea09da2d4453/AU2021/2740',
      'Hash for Session Meta': '9a6e72bf023009d46e505c61e94bea09da2d4453',
    },
    {
      ID: 2724,
      Program: 'Medicine Intensive',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/26/2021',
      'End Date': '7/13/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/61ba030ae8a61b2180469a4477672ff756ee39bc/AU2021/2724',
      'Hash for Session Meta': '61ba030ae8a61b2180469a4477672ff756ee39bc',
    },
    {
      ID: 2722,
      Program: 'Medicine Intensive',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/19/2021',
      'End Date': '8/5/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/9bc8bcf904d706145f55c6c5d4dd8d153e4a9af8/AU2021/2722',
      'Hash for Session Meta': '9bc8bcf904d706145f55c6c5d4dd8d153e4a9af8',
    },
    {
      ID: 2547,
      Program: 'Intelligence & National Security',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/10/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/89ef36403eda242b75c74f0c5f52bed05eb29803/AU2021/2547',
      'Hash for Session Meta': '89ef36403eda242b75c74f0c5f52bed05eb29803',
    },
    {
      ID: 2548,
      Program: 'Intelligence & National Security',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/12/2021',
      'End Date': '7/17/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/06cbcd8d550fc2fe97918818d685521af66e395e/AU2021/2548',
      'Hash for Session Meta': '06cbcd8d550fc2fe97918818d685521af66e395e',
    },
    {
      ID: 2549,
      Program: 'Intelligence & National Security',
      'Session#': 3,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/20/2021',
      'End Date': '7/25/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/5ea2d588001b990992f063cb888197daa9fe50c1/AU2021/2549',
      'Hash for Session Meta': '5ea2d588001b990992f063cb888197daa9fe50c1',
    },
    {
      ID: 2734,
      Program: 'Law & Advocacy',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/11/2021',
      'End Date': '6/19/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/5250f334f8802c4aac8d519ed25c0d51e2ec8439/AU2021/2734',
      'Hash for Session Meta': '5250f334f8802c4aac8d519ed25c0d51e2ec8439',
    },
    {
      ID: 2550,
      Program: 'Nursing',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/6d2246d886ce9da26f38169afa513b11aa45ba58/AU2021/2550',
      'Hash for Session Meta': '6d2246d886ce9da26f38169afa513b11aa45ba58',
    },
    {
      ID: 2551,
      Program: 'Nursing',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/79c13a3cfcc8e3e5ef37803b6696e1c5e3fe775d/AU2021/2551',
      'Hash for Session Meta': '79c13a3cfcc8e3e5ef37803b6696e1c5e3fe775d',
    },
    {
      ID: 2552,
      Program: 'Nursing',
      'Session#': 3,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/28/2021',
      'End Date': '8/5/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/f1b5f95ba34373a87c9b8c87c167eb7ee43bf529/AU2021/2552',
      'Hash for Session Meta': 'f1b5f95ba34373a87c9b8c87c167eb7ee43bf529',
    },
    {
      ID: 2553,
      Program: 'Political Action & Public Policy',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/caaea6272b236149fa2c5780784eb453e3c9e38d/AU2021/2553',
      'Hash for Session Meta': 'caaea6272b236149fa2c5780784eb453e3c9e38d',
    },
    {
      ID: 2554,
      Program: 'Political Action & Public Policy',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/cd7c717273044133ec9e8a8c4cb254b339d615fa/AU2021/2554',
      'Hash for Session Meta': 'cd7c717273044133ec9e8a8c4cb254b339d615fa',
    },
    {
      ID: 2555,
      Program: 'Psychology & Neuroscience',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/11/2021',
      'End Date': '6/19/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/8fa829f22e96204e3c06315dc48e635351c4cc65/AU2021/2555',
      'Hash for Session Meta': '8fa829f22e96204e3c06315dc48e635351c4cc65',
    },
    {
      ID: 2556,
      Program: 'Psychology & Neuroscience',
      'Session#': 2,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/5f992ae9f57ad6d77fdf3f0b59f92509cd43e604/AU2021/2556',
      'Hash for Session Meta': '5f992ae9f57ad6d77fdf3f0b59f92509cd43e604',
    },
    {
      ID: 2557,
      Program: 'Psychology & Neuroscience',
      'Session#': 3,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/82d98f31a5b91f6c041c0b535292be447a37ffb9/AU2021/2557',
      'Hash for Session Meta': '82d98f31a5b91f6c041c0b535292be447a37ffb9',
    },
    {
      ID: 2558,
      Program: 'Psychology & Neuroscience',
      'Session#': 4,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/93f0fd544c7922ea93599ca26e85a44a17b28bcd/AU2021/2558',
      'Hash for Session Meta': '93f0fd544c7922ea93599ca26e85a44a17b28bcd',
    },
    {
      ID: 2559,
      Program: 'Psychology & Neuroscience',
      'Session#': 5,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '7/28/2021',
      'End Date': '8/5/2021',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/a12c69d4dcccf6ff9e9b155f00964f42002e0dcb/AU2021/2559',
      'Hash for Session Meta': 'a12c69d4dcccf6ff9e9b155f00964f42002e0dcb',
    },
    {
      ID: 1418,
      Program: 'Test Program',
      'Session#': 1,
      Location: 'American University',
      Code: 'AU',
      Year: 2021,
      'Start Date': '',
      'End Date': '',
      Blog: 'american.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/2ea07fff9f82f7618079efd004c6c8a5c27b496b/AU2021/1418',
      'Hash for Session Meta': '2ea07fff9f82f7618079efd004c6c8a5c27b496b',
    },
    {
      ID: 2575,
      Program: 'Data Science',
      'Session#': 1,
      Location: 'Georgia Tech',
      Code: 'GT',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'georgiatech.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/3c5344e055a1c01363f20a31fd31cdc65cfa48f0/GT2021/2575',
      'Hash for Session Meta': '3c5344e055a1c01363f20a31fd31cdc65cfa48f0',
    },
    {
      ID: 2576,
      Program: 'Data Science',
      'Session#': 2,
      Location: 'Georgia Tech',
      Code: 'GT',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'georgiatech.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/2f28d0848252ed5864aaaaccb720e35607221c49/GT2021/2576',
      'Hash for Session Meta': '2f28d0848252ed5864aaaaccb720e35607221c49',
    },
    {
      ID: 2577,
      Program: 'Engineering',
      'Session#': 1,
      Location: 'Georgia Tech',
      Code: 'GT',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'georgiatech.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/95d99b94d7d2d61b704f241d61457da7963dc206/GT2021/2577',
      'Hash for Session Meta': '95d99b94d7d2d61b704f241d61457da7963dc206',
    },
    {
      ID: 2578,
      Program: 'Engineering',
      'Session#': 2,
      Location: 'Georgia Tech',
      Code: 'GT',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'georgiatech.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/e175387773bfb740a7a780e9598bcb45b3ea0c9d/GT2021/2578',
      'Hash for Session Meta': 'e175387773bfb740a7a780e9598bcb45b3ea0c9d',
    },
    {
      ID: 2736,
      Program: 'Business & Entrepreneurship',
      'Session#': 1,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/6f30f54b9b5519ffe3f1db3d9f608f12aa52a917/GU2021/2736',
      'Hash for Session Meta': '6f30f54b9b5519ffe3f1db3d9f608f12aa52a917',
    },
    {
      ID: 2737,
      Program: 'Business & Entrepreneurship',
      'Session#': 2,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/61b04f583d1ba4664d46b73ae2583c045ee9de3a/GU2021/2737',
      'Hash for Session Meta': '61b04f583d1ba4664d46b73ae2583c045ee9de3a',
    },
    {
      ID: 2579,
      Program: 'Business Intensive',
      'Session#': 1,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '8/3/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/f886ed1a8687fd96811f8036318dd6a135b71864/GU2021/2579',
      'Hash for Session Meta': 'f886ed1a8687fd96811f8036318dd6a135b71864',
    },
    {
      ID: 2545,
      Program: 'Middle School',
      'Session#': 1,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '6/27/2021',
      'End Date': '7/2/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/bd74625dc01a2d04abc44d7ac61838c4a926d549/GU2021/2545',
      'Hash for Session Meta': 'bd74625dc01a2d04abc44d7ac61838c4a926d549',
    },
    {
      ID: 2580,
      Program: 'International Business',
      'Session#': 1,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/5609e439877150172e0d6f1d6f67ffee1de60b72/GU2021/2580',
      'Hash for Session Meta': '5609e439877150172e0d6f1d6f67ffee1de60b72',
    },
    {
      ID: 2581,
      Program: 'International Business',
      'Session#': 2,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/4e2dc2f1a36cbe6011574a4a4d89ac258dccd746/GU2021/2581',
      'Hash for Session Meta': '4e2dc2f1a36cbe6011574a4a4d89ac258dccd746',
    },
    {
      ID: 2582,
      Program: 'International Diplomacy',
      'Session#': 1,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/3187a8d4a9e383eea7b2464cb18dc59fd40ee79c/GU2021/2582',
      'Hash for Session Meta': '3187a8d4a9e383eea7b2464cb18dc59fd40ee79c',
    },
    {
      ID: 2583,
      Program: 'International Diplomacy',
      'Session#': 2,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/6abb2b36165ed010ae88a6c3f8cdf426a198939f/GU2021/2583',
      'Hash for Session Meta': '6abb2b36165ed010ae88a6c3f8cdf426a198939f',
    },
    {
      ID: 2584,
      Program: 'International Diplomacy',
      'Session#': 3,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/a353875b16311d642e4d01a29aadc1a6432cc3b9/GU2021/2584',
      'Hash for Session Meta': 'a353875b16311d642e4d01a29aadc1a6432cc3b9',
    },
    {
      ID: 2585,
      Program: 'International Diplomacy',
      'Session#': 4,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/28/2021',
      'End Date': '8/5/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/03edc137ca52ced6fb3a2f5c1874d8bcfb20ff77/GU2021/2585',
      'Hash for Session Meta': '03edc137ca52ced6fb3a2f5c1874d8bcfb20ff77',
    },
    {
      ID: 2586,
      Program: 'Law & Advocacy',
      'Session#': 1,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/dfedff3f1b81b1ac7b969b82ff0a2158c0c70f0b/GU2021/2586',
      'Hash for Session Meta': 'dfedff3f1b81b1ac7b969b82ff0a2158c0c70f0b',
    },
    {
      ID: 2587,
      Program: 'Law & Advocacy',
      'Session#': 2,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/0a3cb57a9a310d3d65073b311f924fc274e44ef5/GU2021/2587',
      'Hash for Session Meta': '0a3cb57a9a310d3d65073b311f924fc274e44ef5',
    },
    {
      ID: 2588,
      Program: 'Law & Advocacy',
      'Session#': 3,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '7/25/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/a0f3c6a37fbd15f2ed7aed9efe2aac43cf620909/GU2021/2588',
      'Hash for Session Meta': 'a0f3c6a37fbd15f2ed7aed9efe2aac43cf620909',
    },
    {
      ID: 2589,
      Program: 'Law & Advocacy',
      'Session#': 4,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/28/2021',
      'End Date': '8/5/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/25943b6a363dfae4e41402f9b9316534ede7a475/GU2021/2589',
      'Hash for Session Meta': '25943b6a363dfae4e41402f9b9316534ede7a475',
    },
    {
      ID: 2590,
      Program: 'Law Intensive',
      'Session#': 1,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/17/2021',
      'End Date': '8/3/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/8f4cde13e1716ef998240c37c3aa7ba8a9f80c25/GU2021/2590',
      'Hash for Session Meta': '8f4cde13e1716ef998240c37c3aa7ba8a9f80c25',
    },
    {
      ID: 2591,
      Program: 'Mastering Leadership',
      'Session#': 1,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/15/2021',
      'End Date': '7/20/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/59182604d96c93268da9262c7051f3479a329760/GU2021/2591',
      'Hash for Session Meta': '59182604d96c93268da9262c7051f3479a329760',
    },
    {
      ID: 2592,
      Program: 'Mastering Leadership',
      'Session#': 2,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/23/2021',
      'End Date': '7/28/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/7b2257f6035437b5d23197b8f3ab08e607348405/GU2021/2592',
      'Hash for Session Meta': '7b2257f6035437b5d23197b8f3ab08e607348405',
    },
    {
      ID: 2593,
      Program: 'Mastering Leadership',
      'Session#': 3,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/31/2021',
      'End Date': '8/5/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/85c63ff5816a533909d9d4e3b626e8976365cb09/GU2021/2593',
      'Hash for Session Meta': '85c63ff5816a533909d9d4e3b626e8976365cb09',
    },
    {
      ID: 2738,
      Program: 'Sports Management',
      'Session#': 1,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '6/23/2021',
      'End Date': '7/1/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/9eeed35da68e052469e6a447a0e9a7199da5d4b5/GU2021/2738',
      'Hash for Session Meta': '9eeed35da68e052469e6a447a0e9a7199da5d4b5',
    },
    {
      ID: 2739,
      Program: 'Sports Management',
      'Session#': 2,
      Location: 'Washington, D.C.',
      Code: 'GU',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'dc.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/af95b8ffabb88c44811ea518a0aab62fb01d8fba/GU2021/2739',
      'Hash for Session Meta': 'af95b8ffabb88c44811ea518a0aab62fb01d8fba',
    },
    {
      ID: 2626,
      Program: 'Engineering',
      'Session#': 1,
      Location: 'University of Houston',
      Code: 'RU',
      Year: 2021,
      'Start Date': '7/12/2021',
      'End Date': '7/20/2021',
      Blog: 'houston.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/95cd4d9f97d90c69adddd826c1b81a10233e3c19/RU2021/2626',
      'Hash for Session Meta': '95cd4d9f97d90c69adddd826c1b81a10233e3c19',
    },
    {
      ID: 2627,
      Program: 'Engineering',
      'Session#': 2,
      Location: 'University of Houston',
      Code: 'RU',
      Year: 2021,
      'Start Date': '7/24/2021',
      'End Date': '8/1/2021',
      Blog: 'houston.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/0e27bff432511bc8702ae1e7c0e0af6519811de2/RU2021/2627',
      'Hash for Session Meta': '0e27bff432511bc8702ae1e7c0e0af6519811de2',
    },
    {
      ID: 2628,
      Program: 'Medicine & Health Care',
      'Session#': '1-A',
      Location: 'University of Houston',
      Code: 'RU',
      Year: 2021,
      'Start Date': '7/12/2021',
      'End Date': '7/20/2021',
      Blog: 'houston.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/b08b37c0b002c4f5142536b6ed59f3764944c58a/RU2021/2628',
      'Hash for Session Meta': 'b08b37c0b002c4f5142536b6ed59f3764944c58a',
    },
    {
      ID: 2741,
      Program: 'Medicine & Health Care',
      'Session#': '1-B',
      Location: 'University of Houston',
      Code: 'RU',
      Year: 2021,
      'Start Date': '7/12/2021',
      'End Date': '7/20/2021',
      Blog: 'houston.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/4d71c2688b128c3df0253680eca5b40b42f227dd/RU2021/2741',
      'Hash for Session Meta': '4d71c2688b128c3df0253680eca5b40b42f227dd',
    },
    {
      ID: 2629,
      Program: 'Medicine & Health Care',
      'Session#': '2-A',
      Location: 'University of Houston',
      Code: 'RU',
      Year: 2021,
      'Start Date': '7/24/2021',
      'End Date': '8/1/2021',
      Blog: 'houston.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/2b85c32402af364690c2959d08dc0013d6efbd1b/RU2021/2629',
      'Hash for Session Meta': '2b85c32402af364690c2959d08dc0013d6efbd1b',
    },
    {
      ID: 2742,
      Program: 'Medicine & Health Care',
      'Session#': '2-B',
      Location: 'University of Houston',
      Code: 'RU',
      Year: 2021,
      'Start Date': '7/24/2021',
      'End Date': '8/1/2021',
      Blog: 'houston.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/75aab612d1a570f4fa234c2860466e025ff7b8ca/RU2021/2742',
      'Hash for Session Meta': '75aab612d1a570f4fa234c2860466e025ff7b8ca',
    },
    {
      ID: 2632,
      Program: 'Medicine & Health Care',
      'Session#': 1,
      Location: 'University of San Diego',
      Code: 'USD',
      Year: 2021,
      'Start Date': '6/24/2021',
      'End Date': '7/2/2021',
      Blog: 'usd.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/e672bbe0cf9ea4a25a560a43da2f8c3b664f0ad5/USD2021/2632',
      'Hash for Session Meta': 'e672bbe0cf9ea4a25a560a43da2f8c3b664f0ad5',
    },
    {
      ID: 2633,
      Program: 'Medicine & Health Care',
      'Session#': 2,
      Location: 'University of San Diego',
      Code: 'USD',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'usd.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/db4856d0abad9e930a6e85532e6897897b49566d/USD2021/2633',
      'Hash for Session Meta': 'db4856d0abad9e930a6e85532e6897897b49566d',
    },
    {
      ID: 2643,
      Program: 'Medicine & Health Care',
      'Session#': 2,
      Location: 'University of San Diego',
      Code: 'USD',
      Year: 2021,
      'Start Date': '7/25/2021',
      'End Date': '8/2/2021',
      Blog: 'usd.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/7cc239f8028d7d5c62f3a9f9ab274f90f34ee6f4/USD2021/2643',
      'Hash for Session Meta': '7cc239f8028d7d5c62f3a9f9ab274f90f34ee6f4',
    },
    {
      ID: 2644,
      Program: 'Medicine & Health Care',
      'Session#': 3,
      Location: 'University of San Diego',
      Code: 'USD',
      Year: 2021,
      'Start Date': '8/6/2021',
      'End Date': '8/14/2021',
      Blog: 'usd.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/f220449b8112f3c20df6c60c85d4c59c5b43dca8/USD2021/2644',
      'Hash for Session Meta': 'f220449b8112f3c20df6c60c85d4c59c5b43dca8',
    },
    {
      ID: 2645,
      Program: 'Marine Biology',
      'Session#': 1,
      Location: 'University of San Diego',
      Code: 'USD',
      Year: 2021,
      'Start Date': '7/25/2021',
      'End Date': '8/2/2021',
      Blog: 'usd.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/75dc33547c90defc54c7cbade813817aba47b715/USD2021/2645',
      'Hash for Session Meta': '75dc33547c90defc54c7cbade813817aba47b715',
    },
    {
      ID: 2646,
      Program: 'Marine Biology',
      'Session#': 2,
      Location: 'University of San Diego',
      Code: 'USD',
      Year: 2021,
      'Start Date': '8/6/2021',
      'End Date': '8/14/2021',
      Blog: 'usd.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/ef6ab7478a0458a7e13e328b388bdc4698f11e9f/USD2021/2646',
      'Hash for Session Meta': 'ef6ab7478a0458a7e13e328b388bdc4698f11e9f',
    },
    {
      ID: 2729,
      Program: 'Business & Entrepreneurship',
      'Session#': 1,
      Location: 'University of San Diego',
      Code: 'USD',
      Year: 2021,
      'Start Date': '6/24/2021',
      'End Date': '7/2/2021',
      Blog: 'usd.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/b83c86163b3d57614cf7b20a467c83151e806c2a/USD2021/2729',
      'Hash for Session Meta': 'b83c86163b3d57614cf7b20a467c83151e806c2a',
    },
    {
      ID: 2730,
      Program: 'Business & Entrepreneurship',
      'Session#': 2,
      Location: 'University of San Diego',
      Code: 'USD',
      Year: 2021,
      'Start Date': '7/5/2021',
      'End Date': '7/13/2021',
      Blog: 'usd.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/ef0907cce111f26b275b643de6055443be690be9/USD2021/2730',
      'Hash for Session Meta': 'ef0907cce111f26b275b643de6055443be690be9',
    },
    {
      ID: 2731,
      Program: 'Environmental Science & Sustainability',
      'Session#': 1,
      Location: 'University of San Diego',
      Code: 'USD',
      Year: 2021,
      'Start Date': '7/25/2021',
      'End Date': '8/2/2021',
      Blog: 'usd.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/89b966382b839e0b1f86c6650b6104182bc7bcca/USD2021/2731',
      'Hash for Session Meta': '89b966382b839e0b1f86c6650b6104182bc7bcca',
    },
    {
      ID: 2732,
      Program: 'Environmental Science & Sustainability',
      'Session#': 2,
      Location: 'University of San Diego',
      Code: 'USD',
      Year: 2021,
      'Start Date': '8/6/2021',
      'End Date': '8/14/2021',
      Blog: 'usd.nslcleaders.org',
      Status: 'Active',
      URL: 'https://schedules.nslcleaders.org/1c7b43d60f11b5d944338d74ea59ae0b96fa02a7/USD2021/2732',
      'Hash for Session Meta': '1c7b43d60f11b5d944338d74ea59ae0b96fa02a7',
    },
  ];

export default sessions2021;
