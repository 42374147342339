import React, { Component, Fragment } from 'react';
import { groupBy, tail } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { arrayChunks, parseDate, parseHeaderDate, parseTime } from '../utils/helpers';

import ScheduleApi from '../api/Schedule';
import ScheduleItem from '../components/schedule-item/ScheduleItem';
import SessionsApi from '../api/Sessions';

const LOGO = require('../assets/print-logo.png');

const defultSession = {
  ID: '',
  Program: '',
  'Session#': '',
  Location: '',
  Code: '',
  Year: '',
  'Start Date': '',
  'End Date': '',
  Blog: '',
  Status: '',
  URL: '',
  'Hash for Session Meta': '',
};

export default class Schedule extends Component {
  static propTypes = {
    sessionId: PropTypes.string.isRequired,
    workBook: PropTypes.string.isRequired,
  };

  state = {
    schedule: {},
    breakouts: {},
    session: defultSession,
    isLoading: true,
  };

  componentDidMount() {
    const { sessionId, workBook } = this.props;

    this.fetchSchedule(sessionId, workBook);
    this.fetchSession(sessionId, workBook.substr(-4));
  }

  fetchSchedule = async (sessionId, workBook) => {
    const rawEvents = await ScheduleApi.getSheet(workBook);

    // Filter out events from other sessions, inactive events & irrelevant event types
    const sessionEvents = rawEvents.filter(
      event =>
        event['Session ID'] === parseInt(sessionId, 10)
        && event.Status === 'Active'
        && !['Reserve', 'Divider', 'Day Start'].includes(event['Event Type']),
    );

    this.setState({
      breakouts: groupBy(
        sessionEvents.filter(e => e['Event Type'] === 'Breakout'),
        'Date',
      ),
      schedule: groupBy(sessionEvents, 'Date'),
      isLoading: false,
    });
  };

  fetchSession = async (sessionId, year) => {
    const rawSessions = await SessionsApi.getSessions(year);

    const sessionIndex = rawSessions.findIndex(event => event.ID === parseInt(sessionId, 10));

    if (sessionIndex !== -1) {
      this.setState({
        session: rawSessions[sessionIndex],
      });
    }
  };

  renderHeader = ({ session, date }) => (
    <header className="header">
      <div className="header-left">
        <h2 className="program-name">{session.Program}</h2>
        <h2 className="program-date">{session.Location}</h2>
        <h3 className="program-date">{date}</h3>
      </div>
      <div className="header-right">
        <img src={LOGO} alt="NSLC Logo" />
        <a target="blank" href={`http://${session.Blog}`}>
          {session.Blog}
        </a>
      </div>
    </header>
  );

  renderBreakoutEvent = (event, index) => (
    <div className="breakout-event" key={index}>
      <p className="breakout-title">{event['Event Title']}</p>
      <p className="breakout-time">{parseTime(event['Student Start'])}</p>
      {(event.Breakout_Details || '').split(';').map((value, breakoutIndex) => (
        <p className="breakout-room" key={breakoutIndex}>
          {value
            .split(',')
            .map(part => part.trim())
            .join(' - ')}
        </p>
      ))}
    </div>
  );

  renderBreakout = header => {
    const { breakouts } = this.state;

    return (
      <div>
        {header}
        <div className="breakout-body">
          {Object.entries(breakouts).map(([date, events], index) => (
            <Fragment key={index}>
              <div>
                <h3 className="breakout-day">
                  <div>{moment(date).format('ddd')},</div>
                  <div>{moment(date).format('MMM Do')}</div>
                </h3>
                {events.slice(0, 1).map(this.renderBreakoutEvent)}
              </div>
              {tail(events).map(this.renderBreakoutEvent)}
            </Fragment>
          ))}
        </div>
      </div>
    );
  };

  renderLoading() {
    if (this.state.isLoading) {
      return (
        <div className="app">
          <header className="header" style={{ border: 'none' }}>
            <h2 className="program-name">Fetching schedule...</h2>
          </header>
        </div>
      );
    }

    return null;
  }

  render() {
    const { schedule, session } = this.state;

    const dateList = Object.keys(schedule);
    const chunkedDateList = arrayChunks(dateList, 2);

    const header = this.renderHeader({
      session,
      date: `${parseHeaderDate(dateList[0])}, ${session.Year} - ${parseHeaderDate(dateList[dateList.length - 1])}, ${
        session.Year
      }`,
    });

    return (
      this.renderLoading() || (
        <div className="app">
          {chunkedDateList.map(([day1, day2], index) => (
            <Fragment key={index}>
              {header}
              <div className="page-body">
                <div className="column">
                  <h3 className="day-title">{parseDate(day1)}</h3>
                  <ScheduleItem events={schedule[day1]} />
                </div>
                <div className="column-right">
                  {day2 && (
                    <Fragment>
                      <h3 className="day-title">{parseDate(day2)}</h3>
                      <ScheduleItem events={schedule[day2]} />
                    </Fragment>
                  )}
                </div>
              </div>
            </Fragment>
          ))}
          {this.renderBreakout(header)}
        </div>
      )
    );
  }
}
