import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import objectHash from 'object-hash';

import Schedule from './views/Schedule';
// import Urls from './views/Urls';

function HashChecker({
  match: {
    params: { workBook, sessionId, hash },
  },
}) {
  const actualHash = objectHash(`${workBook}-${sessionId}`);

  return hash === actualHash ? (
    <Schedule sessionId={sessionId} workBook={workBook} />
  ) : (
    <h1>Session not found</h1>
  );
}

HashChecker.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      workBook: PropTypes.string,
      sessionId: PropTypes.string,
      hash: PropTypes.string,
    }),
  }).isRequired,
};

function NotFound() {
  return <h1>Page not found</h1>;
}

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/:hash/:workBook/:sessionId" render={HashChecker} />
      {/* <Route path="/9a095171272a3706f2563efff3712923ec157e36/urls" component={Urls} /> */}
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);
