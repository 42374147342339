const sessions2022 = [
    {
        ID: 2751,
        Program: 'Architecture',
        'Session#': 1,
        Location: 'American University',
        Code: 'ARCH',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'e4376101037842d161a144ece1913bd9add39a9b',
        URL: 'https://schedules.nslcleaders.org/e4376101037842d161a144ece1913bd9add39a9b/AU2022/2751',
    },
    {
        ID: 2752,
        Program: 'Architecture',
        'Session#': 2,
        Location: 'American University',
        Code: 'ARCH',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '523a08c6a0a089f45b72ce32dce9636695d412f5',
        URL: 'https://schedules.nslcleaders.org/523a08c6a0a089f45b72ce32dce9636695d412f5/AU2022/2752',
    },
    {
        ID: 2754,
        Program: 'Biotechnology',
        'Session#': 1,
        Location: 'American University',
        Code: 'BIOT',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/19/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '656a919213b2e88c5e5c7fc661a508ed3458524f',
        URL: 'https://schedules.nslcleaders.org/656a919213b2e88c5e5c7fc661a508ed3458524f/AU2022/2754',
    },
    {
        ID: 2755,
        Program: 'Biotechnology',
        'Session#': 2,
        Location: 'American University',
        Code: 'BIOT',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'fa3c0f8100295a563d5e128eebbf7f9bc2e1bb44',
        URL: 'https://schedules.nslcleaders.org/fa3c0f8100295a563d5e128eebbf7f9bc2e1bb44/AU2022/2755',
    },
    {
        ID: 2773,
        Program: 'Cybersecurity',
        'Session#': 1,
        Location: 'American University',
        Code: 'CYBR',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/19/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'ae79fda8d01cee02bdb7ed4f3eb0e456f5df54f9',
        URL: 'https://schedules.nslcleaders.org/ae79fda8d01cee02bdb7ed4f3eb0e456f5df54f9/AU2022/2773',
    },
    {
        ID: 2774,
        Program: 'Cybersecurity',
        'Session#': 2,
        Location: 'American University',
        Code: 'CYBR',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'f6c0af76db559e4925c8340036b8d38a38f4ab80',
        URL: 'https://schedules.nslcleaders.org/f6c0af76db559e4925c8340036b8d38a38f4ab80/AU2022/2774',
    },
    {
        ID: 2775,
        Program: 'Cybersecurity',
        'Session#': 3,
        Location: 'American University',
        Code: 'CYBR',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '2b66895203ebbfb749eaee86ecb727f65d7caf53',
        URL: 'https://schedules.nslcleaders.org/2b66895203ebbfb749eaee86ecb727f65d7caf53/AU2022/2775',
    },
    {
        ID: 2770,
        Program: 'Film, Journalism & Media Arts',
        'Session#': 1,
        Location: 'American University',
        Code: 'COMM',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/19/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'e1f21f13125662877fb57aa3d564db41d15ec036',
        URL: 'https://schedules.nslcleaders.org/e1f21f13125662877fb57aa3d564db41d15ec036/AU2022/2770',
    },
    {
        ID: 2771,
        Program: 'Film, Journalism & Media Arts',
        'Session#': 2,
        Location: 'American University',
        Code: 'COMM',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '4b2e09388950f710f5bb9e3d0b09e8c3cdf0b469',
        URL: 'https://schedules.nslcleaders.org/4b2e09388950f710f5bb9e3d0b09e8c3cdf0b469/AU2022/2771',
    },
    {
        ID: 2797,
        Program: 'Forensic Science A',
        'Session#': 1,
        Location: 'American University',
        Code: 'FSCI',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/19/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '722fc0c423a36b23aaf0669e6baf65a7384c180d',
        URL: 'https://schedules.nslcleaders.org/722fc0c423a36b23aaf0669e6baf65a7384c180d/AU2022/2797',
    },
    {
        ID: 2798,
        Program: 'Forensic Science A',
        'Session#': 2,
        Location: 'American University',
        Code: 'FSCI',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '63fbaeb3848c3133171bbbd2aec812a60173765e',
        URL: 'https://schedules.nslcleaders.org/63fbaeb3848c3133171bbbd2aec812a60173765e/AU2022/2798',
    },
    {
        ID: 2799,
        Program: 'Forensic Science A',
        'Session#': 3,
        Location: 'American University',
        Code: 'FSCI',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '78ec210a1f58b0aab442a351f4648f7c8725f9fc',
        URL: 'https://schedules.nslcleaders.org/78ec210a1f58b0aab442a351f4648f7c8725f9fc/AU2022/2799',
    },
    {
        ID: 2800,
        Program: 'Forensic Science A',
        'Session#': 4,
        Location: 'American University',
        Code: 'FSCI',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'c293623fe454d719d970c881db99089627f18f00',
        URL: 'https://schedules.nslcleaders.org/c293623fe454d719d970c881db99089627f18f00/AU2022/2800',
    },
    {
        ID: 2851,
        Program: 'Intelligence & National Security',
        'Session#': 1,
        Location: 'American University',
        Code: 'NSEC',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '07/05/2022',
        'End Date': '07/10/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '221a3707d71715ebba6a466805247e59e1416a0e',
        URL: 'https://schedules.nslcleaders.org/221a3707d71715ebba6a466805247e59e1416a0e/AU2022/2851',
    },
    {
        ID: 2852,
        Program: 'Intelligence & National Security',
        'Session#': 2,
        Location: 'American University',
        Code: 'NSEC',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '07/12/2022',
        'End Date': '07/17/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '0ed8702af3af5a55a45a3368fb46e601baa8bc90',
        URL: 'https://schedules.nslcleaders.org/0ed8702af3af5a55a45a3368fb46e601baa8bc90/AU2022/2852',
    },
    {
        ID: 2853,
        Program: 'Intelligence & National Security',
        'Session#': 3,
        Location: 'American University',
        Code: 'NSEC',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '07/19/2022',
        'End Date': '07/24/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'e1c33d5a649f5f3ad96ec3857f319cbfc382bd3f',
        URL: 'https://schedules.nslcleaders.org/e1c33d5a649f5f3ad96ec3857f319cbfc382bd3f/AU2022/2853',
    },
    {
        ID: 2805,
        Program: 'Medicine & Health Care',
        'Session#': 1,
        Location: 'American University',
        Code: 'HEAL',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/19/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '893adcc5bf60c196ba79bd084591d12742db32a8',
        URL: 'https://schedules.nslcleaders.org/893adcc5bf60c196ba79bd084591d12742db32a8/AU2022/2805',
    },
    {
        ID: 2806,
        Program: 'Medicine & Health Care',
        'Session#': 2,
        Location: 'American University',
        Code: 'HEAL',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '06/23/2022',
        'End Date': '7/1/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'b1140e9ea55d99e38685fb79650e375b35563746',
        URL: 'https://schedules.nslcleaders.org/b1140e9ea55d99e38685fb79650e375b35563746/AU2022/2806',
    },
    {
        ID: 2807,
        Program: 'Medicine & Health Care',
        'Session#': 3,
        Location: 'American University',
        Code: 'HEAL',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '97c03a160aabed3920afb21852bf4b1bdb444027',
        URL: 'https://schedules.nslcleaders.org/97c03a160aabed3920afb21852bf4b1bdb444027/AU2022/2807',
    },
    {
        ID: 2808,
        Program: 'Medicine & Health Care',
        'Session#': 4,
        Location: 'American University',
        Code: 'HEAL',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '1d03f9b9804223ee76128c152cac5ab3d5042ce4',
        URL: 'https://schedules.nslcleaders.org/1d03f9b9804223ee76128c152cac5ab3d5042ce4/AU2022/2808',
    },
    {
        ID: 2803,
        Program: 'Medicine Intensive',
        'Session#': 1,
        Location: 'American University',
        Code: 'MINT',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/28/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'dbe555bace4b4306259115b0db59ec6cd56efc0d',
        URL: 'https://schedules.nslcleaders.org/dbe555bace4b4306259115b0db59ec6cd56efc0d/AU2022/2803',
    },
    {
        ID: 2804,
        Program: 'Medicine Intensive',
        'Session#': 2,
        Location: 'American University',
        Code: 'MINT',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '07/05/2022',
        'End Date': '07/22/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '0e4eaa57be311c0f5e9929b76b6abda47cb7c117',
        URL: 'https://schedules.nslcleaders.org/0e4eaa57be311c0f5e9929b76b6abda47cb7c117/AU2022/2804',
    },
    {
        ID: 2837,
        Program: 'Middle School A - Medicine',
        'Session#': 1,
        Location: 'American University',
        Code: 'JNSLC',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '07/05/2022',
        'End Date': '07/10/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '6abcd20d6cd7919276a6686201892287d71adb96',
        URL: 'https://schedules.nslcleaders.org/6abcd20d6cd7919276a6686201892287d71adb96/AU2022/2837',
    },
    {
        ID: 2854,
        Program: 'Nursing',
        'Session#': 1,
        Location: 'American University',
        Code: 'NURS',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '06/11/2022',
        'End Date': '06/19/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'c140662aa41fd55272e7cfdae9c5e52f0971769b',
        URL: 'https://schedules.nslcleaders.org/c140662aa41fd55272e7cfdae9c5e52f0971769b/AU2022/2854',
    },
    {
        ID: 2855,
        Program: 'Nursing',
        'Session#': 2,
        Location: 'American University',
        Code: 'NURS',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '06/23/2022',
        'End Date': '07/01/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'af5f072b73510ee69b6ad740302f955930cf1ee8',
        URL: 'https://schedules.nslcleaders.org/af5f072b73510ee69b6ad740302f955930cf1ee8/AU2022/2855',
    },
    {
        ID: 2856,
        Program: 'Nursing',
        'Session#': 3,
        Location: 'American University',
        Code: 'NURS',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '43849f6d3231b4a1ac68b4190a13b4205f824be2',
        URL: 'https://schedules.nslcleaders.org/43849f6d3231b4a1ac68b4190a13b4205f824be2/AU2022/2856',
    },
    {
        ID: 2857,
        Program: 'Nursing',
        'Session#': 4,
        Location: 'American University',
        Code: 'NURS',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '835a36207b03bc54401e44f252b1e5066bffa94f',
        URL: 'https://schedules.nslcleaders.org/835a36207b03bc54401e44f252b1e5066bffa94f/AU2022/2857',
    },
    {
        ID: 2869,
        Program: 'Political Action & Public Policy',
        'Session#': 1,
        Location: 'American University',
        Code: 'POLI',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/19/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '93ccdf16d9ec35a81dd8beb530e32fcba81682ad',
        URL: 'https://schedules.nslcleaders.org/93ccdf16d9ec35a81dd8beb530e32fcba81682ad/AU2022/2869',
    },
    {
        ID: 2870,
        Program: 'Political Action & Public Policy',
        'Session#': 2,
        Location: 'American University',
        Code: 'POLI',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'ef48e339f7e38b740cc4eb8090ba810bf3cde81c',
        URL: 'https://schedules.nslcleaders.org/ef48e339f7e38b740cc4eb8090ba810bf3cde81c/AU2022/2870',
    },
    {
        ID: 2871,
        Program: 'Psychology & Neuroscience',
        'Session#': 1,
        Location: 'American University',
        Code: 'PSYC',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/19/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '5e3e7991b3b96e8fc8f72472a464121276585a65',
        URL: 'https://schedules.nslcleaders.org/5e3e7991b3b96e8fc8f72472a464121276585a65/AU2022/2871',
    },
    {
        ID: 2872,
        Program: 'Psychology & Neuroscience',
        'Session#': 2,
        Location: 'American University',
        Code: 'PSYC',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '54ccd29bccf51b2b7f19ed03c524c57451455be8',
        URL: 'https://schedules.nslcleaders.org/54ccd29bccf51b2b7f19ed03c524c57451455be8/AU2022/2872',
    },
    {
        ID: 2873,
        Program: 'Psychology & Neuroscience',
        'Session#': 3,
        Location: 'American University',
        Code: 'PSYC',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'ae8e98286fb0d750813daa06fcf434c6a866dfb3',
        URL: 'https://schedules.nslcleaders.org/ae8e98286fb0d750813daa06fcf434c6a866dfb3/AU2022/2873',
    },
    {
        ID: 2874,
        Program: 'Psychology & Neuroscience',
        'Session#': 4,
        Location: 'American University',
        Code: 'PSYC',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '07/17/2022',
        'End Date': '07/25/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '5d496a8f99ab14dbf99a7b1c396c93e7a3ecd1ba',
        URL: 'https://schedules.nslcleaders.org/5d496a8f99ab14dbf99a7b1c396c93e7a3ecd1ba/AU2022/2874',
    },
    {
        ID: 2814,
        Program: 'Medicine & Health Care',
        'Session#': 1,
        Location: 'Boston',
        Code: 'HEAL',
        Loc: 'HMS',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'boston.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '7ff269706b9633484057cea514a9164ec66fe4b9',
        URL: 'https://schedules.nslcleaders.org/7ff269706b9633484057cea514a9164ec66fe4b9/HMS2022/2814',
    },
    {
        ID: 2815,
        Program: 'Medicine & Health Care',
        'Session#': 2,
        Location: 'Boston',
        Code: 'HEAL',
        Loc: 'HMS',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'boston.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '2ea69fc8f80ef73151264f01ce40951f15b9e867',
        URL: 'https://schedules.nslcleaders.org/2ea69fc8f80ef73151264f01ce40951f15b9e867/HMS2022/2815',
    },
    {
        ID: 2816,
        Program: 'Medicine & Health Care',
        'Session#': 3,
        Location: 'Boston',
        Code: 'HEAL',
        Loc: 'HMS',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'boston.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '2f8fa654ec43dcb0b49f33ce393b3c61a87d3c16',
        URL: 'https://schedules.nslcleaders.org/2f8fa654ec43dcb0b49f33ce393b3c61a87d3c16/HMS2022/2816',
    },
    {
        ID: 2817,
        Program: 'Medicine & Health Care',
        'Session#': 4,
        Location: 'Boston',
        Code: 'HEAL',
        Loc: 'HMS',
        Year: 2022,
        'Start Date': '7/29/2022',
        'End Date': '8/6/2022',
        Blog: 'boston.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '92d54e5f868e889d499fc0efefaee10a6f777e6c',
        URL: 'https://schedules.nslcleaders.org/92d54e5f868e889d499fc0efefaee10a6f777e6c/HMS2022/2817',
    },
    {
        ID: 2877,
        Program: 'Psychology & Neuroscience',
        'Session#': 1,
        Location: 'Boston',
        Code: 'PSYC',
        Loc: 'HMS',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'boston.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '29fc87436c8fca98c8026ec60095d47eaeed7206',
        URL: 'https://schedules.nslcleaders.org/29fc87436c8fca98c8026ec60095d47eaeed7206/HMS2022/2877',
    },
    {
        ID: 2878,
        Program: 'Psychology & Neuroscience',
        'Session#': 2,
        Location: 'Boston',
        Code: 'PSYC',
        Loc: 'HMS',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'boston.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '44541ee510cf49afa647c6f4e37b61f270e66867',
        URL: 'https://schedules.nslcleaders.org/44541ee510cf49afa647c6f4e37b61f270e66867/HMS2022/2878',
    },
    {
        ID: 2879,
        Program: 'Psychology & Neuroscience',
        'Session#': 3,
        Location: 'Boston',
        Code: 'PSYC',
        Loc: 'HMS',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'boston.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '1fad13e31b76846de012d88b3464edb98988f484',
        URL: 'https://schedules.nslcleaders.org/1fad13e31b76846de012d88b3464edb98988f484/HMS2022/2879',
    },
    {
        ID: 2880,
        Program: 'Psychology & Neuroscience',
        'Session#': 4,
        Location: 'Boston',
        Code: 'PSYC',
        Loc: 'HMS',
        Year: 2022,
        'Start Date': '07/29/2022',
        'End Date': '08/06/2022',
        Blog: 'boston.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '70bd0ec6792e9623c06f702974aa1ab68f3262e1',
        URL: 'https://schedules.nslcleaders.org/70bd0ec6792e9623c06f702974aa1ab68f3262e1/HMS2022/2880',
    },
    {
        ID: 2758,
        Program: 'Biotechnology',
        'Session#': 1,
        Location: 'Duke University',
        Code: 'BIOT',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '7/6/2022',
        'End Date': '7/14/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'f0556a3c77b48a9cebc2dec9c45debc6c710da43',
        URL: 'https://schedules.nslcleaders.org/f0556a3c77b48a9cebc2dec9c45debc6c710da43/DUKE2022/2758',
    },
    {
        ID: 2759,
        Program: 'Biotechnology',
        'Session#': 2,
        Location: 'Duke University',
        Code: 'BIOT',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '7/18/2022',
        'End Date': '7/26/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'd84e6a090f56ab5800d896522a7b4e38a41dd683',
        URL: 'https://schedules.nslcleaders.org/d84e6a090f56ab5800d896522a7b4e38a41dd683/DUKE2022/2759',
    },
    {
        ID: 2763,
        Program: 'Business & Entrepreneurship',
        'Session#': 1,
        Location: 'Duke University',
        Code: 'BUSI',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '6/12/2022',
        'End Date': '6/20/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '1ace9e0afcbfbe460d6f9b8413c0a49204dc4440',
        URL: 'https://schedules.nslcleaders.org/1ace9e0afcbfbe460d6f9b8413c0a49204dc4440/DUKE2022/2763',
    },
    {
        ID: 2764,
        Program: 'Business & Entrepreneurship',
        'Session#': 2,
        Location: 'Duke University',
        Code: 'BUSI',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'b6f418a60f1553fb486abeff3835dc1a4e3f9e1e',
        URL: 'https://schedules.nslcleaders.org/b6f418a60f1553fb486abeff3835dc1a4e3f9e1e/DUKE2022/2764',
    },
    {
        ID: 2786,
        Program: 'Engineering',
        'Session#': 1,
        Location: 'Duke University',
        Code: 'ENGN',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '6/12/2022',
        'End Date': '6/20/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'c0d44c4e21b4943c5add8d9532cc323b1bed9be0',
        URL: 'https://schedules.nslcleaders.org/c0d44c4e21b4943c5add8d9532cc323b1bed9be0/DUKE2022/2786',
    },
    {
        ID: 2787,
        Program: 'Engineering',
        'Session#': 2,
        Location: 'Duke University',
        Code: 'ENGN',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '462060efe09721a98c4e2f9173f702d4e63ca1a3',
        URL: 'https://schedules.nslcleaders.org/462060efe09721a98c4e2f9173f702d4e63ca1a3/DUKE2022/2787',
    },
    {
        ID: 2864,
        Program: 'Law & Advocacy',
        'Session#': 1,
        Location: 'Duke University',
        Code: 'LAWA',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '7/6/2022',
        'End Date': '7/14/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'b6f418a60f1553fb486abeff3835dc1a4e3f9e1e',
        URL: 'https://schedules.nslcleaders.org/b6f418a60f1553fb486abeff3835dc1a4e3f9e1e/DUKE2022/2864',
    },
    {
        ID: 2865,
        Program: 'Law & Advocacy',
        'Session#': 2,
        Location: 'Duke University',
        Code: 'LAWA',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '0dc2ce43d78f4580dd495a9e0760448066dab9f1',
        URL: 'https://schedules.nslcleaders.org/0dc2ce43d78f4580dd495a9e0760448066dab9f1/DUKE2022/2865',
    },
    {
        ID: 2812,
        Program: 'Medicine & Health Care',
        'Session#': 1,
        Location: 'Duke University',
        Code: 'HEAL',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '6/12/2022',
        'End Date': '6/20/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '3a809308d7e87903c3892652cba70d5b61513738',
        URL: 'https://schedules.nslcleaders.org/3a809308d7e87903c3892652cba70d5b61513738/DUKE2022/2812',
    },
    {
        ID: 2813,
        Program: 'Medicine & Health Care',
        'Session#': 2,
        Location: 'Duke University',
        Code: 'HEAL',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '4f9d5cebbde85d5b0ca4a93ab0a4c28e34a9e05c',
        URL: 'https://schedules.nslcleaders.org/4f9d5cebbde85d5b0ca4a93ab0a4c28e34a9e05c/DUKE2022/2813',
    },
    {
        ID: 2862,
        Program: 'Medicine & Health Care',
        'Session#': 3,
        Location: 'Duke University',
        Code: 'HEAL',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '7/6/2022',
        'End Date': '7/14/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '147da888d40f2d4292ad82dbc0c690f6e1ba41d6',
        URL: 'https://schedules.nslcleaders.org/147da888d40f2d4292ad82dbc0c690f6e1ba41d6/DUKE2022/2862',
    },
    {
        ID: 2863,
        Program: 'Medicine & Health Care',
        'Session#': 4,
        Location: 'Duke University',
        Code: 'HEAL',
        Loc: 'DUKE',
        Year: 2022,
        'Start Date': '07/18/2022',
        'End Date': '07/26/2022',
        Blog: 'duke.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '4d186847971589f90e4dd286e1be53064150ff3e',
        URL: 'https://schedules.nslcleaders.org/4d186847971589f90e4dd286e1be53064150ff3e/DUKE2022/2863',
    },
    {
        ID: 2746,
        Program: 'Acting Intensive',
        'Session#': 1,
        Location: 'Fordham University',
        Code: 'ACTI',
        Loc: 'NY',
        Year: 2022,
        'Start Date': '7/23/2022',
        'End Date': '8/9/2022',
        Blog: 'fordham.nslcleaders.org/',
        Status: 'Active',
        'Hash for Session Meta': '15b76924aca58adc2f8379ab9e19baa8c06d8077',
        URL: 'https://schedules.nslcleaders.org/15b76924aca58adc2f8379ab9e19baa8c06d8077/NY2022/2746',
    },
    {
        ID: 2765,
        Program: 'Business & Entrepreneurship',
        'Session#': 1,
        Location: 'Fordham University',
        Code: 'BUSI',
        Loc: 'NY',
        Year: 2022,
        'Start Date': '7/23/2022',
        'End Date': '7/31/2022',
        Blog: 'fordham.nslcleaders.org/',
        Status: 'Active',
        'Hash for Session Meta': '84f9a852197f8ce826d0fe4c08410403cca3e587',
        URL: 'https://schedules.nslcleaders.org/84f9a852197f8ce826d0fe4c08410403cca3e587/NY2022/2765',
    },
    {
        ID: 2766,
        Program: 'Business & Entrepreneurship',
        'Session#': 2,
        Location: 'Fordham University',
        Code: 'BUSI',
        Loc: 'NY',
        Year: 2022,
        'Start Date': '8/3/2022',
        'End Date': '8/11/2022',
        Blog: 'fordham.nslcleaders.org/',
        Status: 'Active',
        'Hash for Session Meta': '9855cb3f8cc55c130beefe1b3833ebaa1cf07847',
        URL: 'https://schedules.nslcleaders.org/9855cb3f8cc55c130beefe1b3833ebaa1cf07847/NY2022/2766',
    },
    {
        ID: 2858,
        Program: 'Sports Management',
        'Session#': 1,
        Location: 'Fordham University',
        Code: 'SPRT',
        Loc: 'NY',
        Year: 2022,
        'Start Date': '07/23/2022',
        'End Date': '07/31/2022',
        Blog: 'fordham.nslcleaders.org/',
        Status: 'Active',
        'Hash for Session Meta': '1aee02e8343e4dede03437b26f4d3caceedce38a',
        URL: 'https://schedules.nslcleaders.org/1aee02e8343e4dede03437b26f4d3caceedce38a/NY2022/2858',
    },
    {
        ID: 2859,
        Program: 'Sports Management',
        'Session#': 2,
        Location: 'Fordham University',
        Code: 'SPRT',
        Loc: 'NY',
        Year: 2022,
        'Start Date': '8/3/2022',
        'End Date': '8/11/2022',
        Blog: 'fordham.nslcleaders.org/',
        Status: 'Active',
        'Hash for Session Meta': 'fddbec0416dcb6c7a6206fb8a753590986716ed9',
        URL: 'https://schedules.nslcleaders.org/fddbec0416dcb6c7a6206fb8a753590986716ed9/NY2022/2859',
    },
    {
        ID: 2860,
        Program: 'Theater',
        'Session#': 1,
        Location: 'Fordham University',
        Code: 'THTR',
        Loc: 'NY',
        Year: 2022,
        'Start Date': '7/23/2022',
        'End Date': '7/31/2022',
        Blog: 'fordham.nslcleaders.org/',
        Status: 'Active',
        'Hash for Session Meta': 'e341426e17a71e7abdc6cd832717c912ca5b303e',
        URL: 'https://schedules.nslcleaders.org/e341426e17a71e7abdc6cd832717c912ca5b303e/NY2022/2860',
    },
    {
        ID: 2861,
        Program: 'Theater',
        'Session#': 2,
        Location: 'Fordham University',
        Code: 'THTR',
        Loc: 'NY',
        Year: 2022,
        'Start Date': '8/3/2022',
        'End Date': '8/11/2022',
        Blog: 'fordham.nslcleaders.org/',
        Status: 'Active',
        'Hash for Session Meta': '15f4029907a28f4487463d3e467490ec585bb63c',
        URL: 'https://schedules.nslcleaders.org/15f4029907a28f4487463d3e467490ec585bb63c/NY2022/2861',
    },
    {
        ID: 2753,
        Program: 'Business Intensive',
        'Session#': 1,
        Location: 'Georgetown University',
        Code: 'BINT',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '7/19/2022',
        'End Date': '8/5/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '8ddbbe2577887e22b0bf0bab82263bcf2f636bf4',
        URL: 'https://schedules.nslcleaders.org/8ddbbe2577887e22b0bf0bab82263bcf2f636bf4/GU2022/2753',
    },
    {
        ID: 2831,
        Program: 'International Business',
        'Session#': 1,
        Location: 'Georgetown University',
        Code: 'IBUS',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '06/23/2022',
        'End Date': '07/01/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'c19ef936c915a2c528c2f826042024962946c889',
        URL: 'https://schedules.nslcleaders.org/c19ef936c915a2c528c2f826042024962946c889/GU2022/2831',
    },
    {
        ID: 2832,
        Program: 'International Business',
        'Session#': 2,
        Location: 'Georgetown University',
        Code: 'IBUS',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '636dba397ea8293f7062979be6223f2706d2f8ea',
        URL: 'https://schedules.nslcleaders.org/636dba397ea8293f7062979be6223f2706d2f8ea/GU2022/2832',
    },
    {
        ID: 2833,
        Program: 'International Diplomacy',
        'Session#': 1,
        Location: 'Georgetown University',
        Code: 'INTL',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '9d65d4e02b4fe347a121c48b4a9277f9b81aea5e',
        URL: 'https://schedules.nslcleaders.org/9d65d4e02b4fe347a121c48b4a9277f9b81aea5e/GU2022/2833',
    },
    {
        ID: 2834,
        Program: 'International Diplomacy',
        'Session#': 2,
        Location: 'Georgetown University',
        Code: 'INTL',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'bee540c15eae07cfe984cf1dceb2728a806d860f',
        URL: 'https://schedules.nslcleaders.org/bee540c15eae07cfe984cf1dceb2728a806d860f/GU2022/2834',
    },
    {
        ID: 2835,
        Program: 'International Diplomacy',
        'Session#': 3,
        Location: 'Georgetown University',
        Code: 'INTL',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '0b3d43d9e7379e946b8a528bf40d33e939b4e19c',
        URL: 'https://schedules.nslcleaders.org/0b3d43d9e7379e946b8a528bf40d33e939b4e19c/GU2022/2835',
    },
    {
        ID: 2836,
        Program: 'International Diplomacy',
        'Session#': 4,
        Location: 'Georgetown University',
        Code: 'INTL',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '07/28/2022',
        'End Date': '08/05/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '66940d8bfbc7b62eed980a20a8a0f29684d643e9',
        URL: 'https://schedules.nslcleaders.org/66940d8bfbc7b62eed980a20a8a0f29684d643e9/GU2022/2836',
    },
    {
        ID: 2839,
        Program: 'Law & Advocacy',
        'Session#': 1,
        Location: 'Georgetown University',
        Code: 'LAWA',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '9d270c9399d0f15a6f9ca2eb1221008310b93363',
        URL: 'https://schedules.nslcleaders.org/9d270c9399d0f15a6f9ca2eb1221008310b93363/GU2022/2839',
    },
    {
        ID: 2840,
        Program: 'Law & Advocacy',
        'Session#': 2,
        Location: 'Georgetown University',
        Code: 'LAWA',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'ba25e28667092c0bd86bf53c581404338d9d1e34',
        URL: 'https://schedules.nslcleaders.org/ba25e28667092c0bd86bf53c581404338d9d1e34/GU2022/2840',
    },
    {
        ID: 2841,
        Program: 'Law & Advocacy',
        'Session#': 3,
        Location: 'Georgetown University',
        Code: 'LAWA',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '4da11adc6e562a6b83f83ac25ee4fc9c9e64134d',
        URL: 'https://schedules.nslcleaders.org/4da11adc6e562a6b83f83ac25ee4fc9c9e64134d/GU2022/2841',
    },
    {
        ID: 2842,
        Program: 'Law & Advocacy',
        'Session#': 4,
        Location: 'Georgetown University',
        Code: 'LAWA',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '7/28/2022',
        'End Date': '8/5/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '543352bae82e061dda9e9983edc4992291e686bf',
        URL: 'https://schedules.nslcleaders.org/543352bae82e061dda9e9983edc4992291e686bf/GU2022/2842',
    },
    {
        ID: 2843,
        Program: 'Law Intensive',
        'Session#': 1,
        Location: 'Georgetown University',
        Code: 'LINT',
        Loc: 'GU',
        Year: 2022,
        'Start Date': '7/19/2022',
        'End Date': '8/5/2022',
        Blog: 'georgetown.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '41ed958ee9f54bcdbd85a5e215a4b6534ebe5dd4',
        URL: 'https://schedules.nslcleaders.org/41ed958ee9f54bcdbd85a5e215a4b6534ebe5dd4/GU2022/2843',
    },
    {
        ID: 2777,
        Program: 'Data Science',
        'Session#': 1,
        Location: 'Georgia Tech',
        Code: 'DATA',
        Loc: 'GT',
        Year: 2022,
        'Start Date': '7/11/2022',
        'End Date': '7/19/2022',
        Blog: 'georgiatech.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '4383eebb0f03b6cd602d62050f5a51353654bdfb',
        URL: 'https://schedules.nslcleaders.org/4383eebb0f03b6cd602d62050f5a51353654bdfb/GT2022/2777',
    },
    {
        ID: 2778,
        Program: 'Data Science',
        'Session#': 2,
        Location: 'Georgia Tech',
        Code: 'DATA',
        Loc: 'GT',
        Year: 2022,
        'Start Date': '7/23/2022',
        'End Date': '7/31/2022',
        Blog: 'georgiatech.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'ccf113acee25b81512a5a5262ad6360defb52e77',
        URL: 'https://schedules.nslcleaders.org/ccf113acee25b81512a5a5262ad6360defb52e77/GT2022/2778',
    },
    {
        ID: 2779,
        Program: 'Engineering Intensive',
        'Session#': 1,
        Location: 'Georgia Tech',
        Code: 'EINT',
        Loc: 'GT',
        Year: 2022,
        'Start Date': '7/11/2022',
        'End Date': '7/28/2022',
        Blog: 'georgiatech.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '438cc13c0d9f15d750f249766267cfe37bc31281',
        URL: 'https://schedules.nslcleaders.org/438cc13c0d9f15d750f249766267cfe37bc31281/GT2022/2779',
    },
    {
        ID: 2747,
        Program: 'Aerospace',
        'Session#': 1,
        Location: 'Johns Hopkins University',
        Code: 'AERO',
        Loc: 'JHU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/19/2022',
        Blog: 'jhu.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '95d178ec10d5a4ef1cc37a6809c937f66cc238a2',
        URL: 'https://schedules.nslcleaders.org/95d178ec10d5a4ef1cc37a6809c937f66cc238a2/JHU2022/2747',
    },
    {
        ID: 2748,
        Program: 'Aerospace',
        'Session#': 2,
        Location: 'Johns Hopkins University',
        Code: 'AERO',
        Loc: 'JHU',
        Year: 2022,
        'Start Date': '6/22/2022',
        'End Date': '6/30/2022',
        Blog: 'jhu.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '77f4129ac864dd3472fcb64912aadc2e8072b655',
        URL: 'https://schedules.nslcleaders.org/77f4129ac864dd3472fcb64912aadc2e8072b655/JHU2022/2748',
    },
    {
        ID: 2749,
        Program: 'Aerospace',
        'Session#': 3,
        Location: 'Johns Hopkins University',
        Code: 'AERO',
        Loc: 'JHU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'jhu.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'd1e1cdcc64998b2fbe4893b26bdd314aa7f6ee24',
        URL: 'https://schedules.nslcleaders.org/d1e1cdcc64998b2fbe4893b26bdd314aa7f6ee24/JHU2022/2749',
    },
    {
        ID: 2750,
        Program: 'Aerospace',
        'Session#': 4,
        Location: 'Johns Hopkins University',
        Code: 'AERO',
        Loc: 'JHU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'jhu.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'dd9aac3640fa0b4c0bd67d0c01422f65b5380f8f',
        URL: 'https://schedules.nslcleaders.org/dd9aac3640fa0b4c0bd67d0c01422f65b5380f8f/JHU2022/2750',
    },
    {
        ID: 2780,
        Program: 'Engineering',
        'Session#': 1,
        Location: 'Johns Hopkins University',
        Code: 'ENGN',
        Loc: 'JHU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'jhu.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '9755b45b480ec45220e39fbdfad016f8324247c5',
        URL: 'https://schedules.nslcleaders.org/9755b45b480ec45220e39fbdfad016f8324247c5/JHU2022/2780',
    },
    {
        ID: 2781,
        Program: 'Engineering',
        'Session#': 2,
        Location: 'Johns Hopkins University',
        Code: 'ENGN',
        Loc: 'JHU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'jhu.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '3216f3242aeabb623da0a30a1be8c69f8aad562d',
        URL: 'https://schedules.nslcleaders.org/3216f3242aeabb623da0a30a1be8c69f8aad562d/JHU2022/2781',
    },
    {
        ID: 2844,
        Program: 'Mastering Leadership',
        'Session#': 1,
        Location: 'Johns Hopkins University',
        Code: 'MAST',
        Loc: 'JHU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/16/2022',
        Blog: 'jhu.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '78fc9020c08782c04abb67367e692a032d920cc3',
        URL: 'https://schedules.nslcleaders.org/78fc9020c08782c04abb67367e692a032d920cc3/JHU2022/2844',
    },
    {
        ID: 2845,
        Program: 'Mastering Leadership',
        'Session#': 2,
        Location: 'Johns Hopkins University',
        Code: 'MAST',
        Loc: 'JHU',
        Year: 2022,
        'Start Date': '6/19/2022',
        'End Date': '6/24/2022',
        Blog: 'jhu.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '10b7922792293c21e24af5a3bd4f460ea88f5dc0',
        URL: 'https://schedules.nslcleaders.org/10b7922792293c21e24af5a3bd4f460ea88f5dc0/JHU2022/2845',
    },
    {
        ID: 2846,
        Program: 'Mastering Leadership',
        'Session#': 3,
        Location: 'Johns Hopkins University',
        Code: 'MAST',
        Loc: 'JHU',
        Year: 2022,
        'Start Date': '06/27/2022',
        'End Date': '07/02/2022',
        Blog: 'jhu.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '18b1595e83bef5c3781de86183d35f634680af17',
        URL: 'https://schedules.nslcleaders.org/18b1595e83bef5c3781de86183d35f634680af17/JHU2022/2846',
    },
    {
        ID: 2818,
        Program: 'Medicine & Health Care',
        'Session#': 1,
        Location: 'Northwestern University',
        Code: 'HEAL',
        Loc: 'NU',
        Year: 2022,
        'Start Date': '6/25/2022',
        'End Date': '7/3/2022',
        Blog: 'northwestern.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '66795fd5dd9bd88ffc6dd0c3ee08c13ab83667b6',
        URL: 'https://schedules.nslcleaders.org/66795fd5dd9bd88ffc6dd0c3ee08c13ab83667b6/NU2022/2818',
    },
    {
        ID: 2819,
        Program: 'Medicine & Health Care',
        'Session#': 2,
        Location: 'Northwestern University',
        Code: 'HEAL',
        Loc: 'NU',
        Year: 2022,
        'Start Date': '7/7/2022',
        'End Date': '7/15/2022',
        Blog: 'northwestern.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'cf0f1ba8a0bc2ffb6ae50360905f39b3c810f3cf',
        URL: 'https://schedules.nslcleaders.org/cf0f1ba8a0bc2ffb6ae50360905f39b3c810f3cf/NU2022/2819',
    },
    {
        ID: 2821,
        Program: 'Medicine & Health Care',
        'Session#': 3,
        Location: 'Northwestern University',
        Code: 'HEAL',
        Loc: 'NU',
        Year: 2022,
        'Start Date': '7/19/2022',
        'End Date': '7/27/2022',
        Blog: 'northwestern.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '5f11b14b14065ee8c1f5de78388e8c47aeb0f7ba',
        URL: 'https://schedules.nslcleaders.org/5f11b14b14065ee8c1f5de78388e8c47aeb0f7ba/NU2022/2821',
    },
    {
        ID: 2822,
        Program: 'Medicine & Health Care',
        'Session#': 4,
        Location: 'Northwestern University',
        Code: 'HEAL',
        Loc: 'NU',
        Year: 2022,
        'Start Date': '7/31/2022',
        'End Date': '8/8/2022',
        Blog: 'northwestern.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'c1ab0e6f06925c2f2b13376cf22c5e92dce94a3a',
        URL: 'https://schedules.nslcleaders.org/c1ab0e6f06925c2f2b13376cf22c5e92dce94a3a/NU2022/2822',
    },
    {
        ID: 2882,
        Program: 'Veterinary Medicine',
        'Session#': 1,
        Location: 'Penn Vet',
        Code: 'VETM',
        Loc: 'UP',
        Year: 2022,
        'Start Date': '7/26/2022',
        'End Date': '8/3/2022',
        Blog: 'penn.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'fe851937c5648d0e8d478407b4663eff9cbb3ee8',
        URL: 'https://schedules.nslcleaders.org/fe851937c5648d0e8d478407b4663eff9cbb3ee8/UP2022/2882',
    },
    {
        ID: 2883,
        Program: 'Veterinary Medicine',
        'Session#': 2,
        Location: 'Penn Vet',
        Code: 'VETM',
        Loc: 'UP',
        Year: 2022,
        'Start Date': '8/6/2022',
        'End Date': '8/14/2022',
        Blog: 'penn.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '5cd77e963685846a9dfeb604c229dd424ee64d80',
        URL: 'https://schedules.nslcleaders.org/5cd77e963685846a9dfeb604c229dd424ee64d80/UP2022/2883',
    },
    {
        ID: 2756,
        Program: 'Biotechnology',
        'Session#': 1,
        Location: 'UC Berkeley',
        Code: 'BIOT',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '7/18/2022',
        'End Date': '7/26/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'b8fa7499cdbd6fb0b71c6f1ccb2a82a8b37baf31',
        URL: 'https://schedules.nslcleaders.org/b8fa7499cdbd6fb0b71c6f1ccb2a82a8b37baf31/CA2022/2756',
    },
    {
        ID: 2757,
        Program: 'Biotechnology',
        'Session#': 2,
        Location: 'UC Berkeley',
        Code: 'BIOT',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '7/30/2022',
        'End Date': '8/7/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '46a67710050ca4bcb01492319506b5fc842da822',
        URL: 'https://schedules.nslcleaders.org/46a67710050ca4bcb01492319506b5fc842da822/CA2022/2757',
    },
    {
        ID: 2760,
        Program: 'Business & Entrepreneurship',
        'Session#': 1,
        Location: 'UC Berkeley',
        Code: 'BUSI',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '178fff168b0c8bbd690b0233b6bb272ff45539b9',
        URL: 'https://schedules.nslcleaders.org/178fff168b0c8bbd690b0233b6bb272ff45539b9/CA2022/2760',
    },
    {
        ID: 2761,
        Program: 'Business & Entrepreneurship',
        'Session#': 2,
        Location: 'UC Berkeley',
        Code: 'BUSI',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '7/6/2022',
        'End Date': '7/14/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '1b583d1936759e59ea826b4002addf0ceae6c101',
        URL: 'https://schedules.nslcleaders.org/1b583d1936759e59ea826b4002addf0ceae6c101/CA2022/2761',
    },
    {
        ID: 2762,
        Program: 'Business & Entrepreneurship',
        'Session#': 3,
        Location: 'UC Berkeley',
        Code: 'BUSI',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '7/18/2022',
        'End Date': '7/26/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'b229d85e9e154d5fc378ff8010759bed5b9fe432',
        URL: 'https://schedules.nslcleaders.org/b229d85e9e154d5fc378ff8010759bed5b9fe432/CA2022/2762',
    },
    {
        ID: 2782,
        Program: 'Engineering',
        'Session#': 1,
        Location: 'UC Berkeley',
        Code: 'ENGN',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '6f5befe2b9730fbfa712b4a2d28982e0e51e1451',
        URL: 'https://schedules.nslcleaders.org/6f5befe2b9730fbfa712b4a2d28982e0e51e1451/CA2022/2782',
    },
    {
        ID: 2783,
        Program: 'Engineering',
        'Session#': 2,
        Location: 'UC Berkeley',
        Code: 'ENGN',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '7/6/2022',
        'End Date': '7/14/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'bc6fd71dc15eb8986150fc427a0a89076984714b',
        URL: 'https://schedules.nslcleaders.org/bc6fd71dc15eb8986150fc427a0a89076984714b/CA2022/2783',
    },
    {
        ID: 2784,
        Program: 'Engineering',
        'Session#': 3,
        Location: 'UC Berkeley',
        Code: 'ENGN',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '07/18/2022',
        'End Date': '07/26/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'da3af96f850f6cad64cf6854a89d8d1495f38e5c',
        URL: 'https://schedules.nslcleaders.org/da3af96f850f6cad64cf6854a89d8d1495f38e5c/CA2022/2784',
    },
    {
        ID: 2785,
        Program: 'Engineering',
        'Session#': 4,
        Location: 'UC Berkeley',
        Code: 'ENGN',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '07/30/2022',
        'End Date': '08/07/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '4af9b8a1210c65ff0a9b033843fa79b20b00ac49',
        URL: 'https://schedules.nslcleaders.org/4af9b8a1210c65ff0a9b033843fa79b20b00ac49/CA2022/2785',
    },
    {
        ID: 2772,
        Program: 'Film, Journalism & Media Arts',
        'Session#': 1,
        Location: 'UC Berkeley',
        Code: 'COMM',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '7/30/2022',
        'End Date': '8/7/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'ee611039c211fb5b12f4f570cee72cbe86bbce09',
        URL: 'https://schedules.nslcleaders.org/ee611039c211fb5b12f4f570cee72cbe86bbce09/CA2022/2772',
    },
    {
        ID: 2809,
        Program: 'Medicine & Health Care',
        'Session#': 1,
        Location: 'UC Berkeley',
        Code: 'HEAL',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '5fdb37dc062017dfa52aaa5a6d520cbd52f8e987',
        URL: 'https://schedules.nslcleaders.org/5fdb37dc062017dfa52aaa5a6d520cbd52f8e987/CA2022/2809',
    },
    {
        ID: 2820,
        Program: 'Medicine & Health Care',
        'Session#': 2,
        Location: 'UC Berkeley',
        Code: 'HEAL',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '7/6/2022',
        'End Date': '7/14/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '2d077816c1d595f01b567d24bacda5586cc9c5ca',
        URL: 'https://schedules.nslcleaders.org/2d077816c1d595f01b567d24bacda5586cc9c5ca/CA2022/2820',
    },
    {
        ID: 2810,
        Program: 'Medicine & Health Care',
        'Session#': 3,
        Location: 'UC Berkeley',
        Code: 'HEAL',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '7/18/2022',
        'End Date': '7/26/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '11a6e7d3831abb8e43968eb47c2a6f0383eb39bf',
        URL: 'https://schedules.nslcleaders.org/11a6e7d3831abb8e43968eb47c2a6f0383eb39bf/CA2022/2810',
    },
    {
        ID: 2811,
        Program: 'Medicine & Health Care',
        'Session#': 4,
        Location: 'UC Berkeley',
        Code: 'HEAL',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '7/30/2022',
        'End Date': '8/7/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '7806ca6d7c2ff9267887f4b97f83ec4ff4deb98b',
        URL: 'https://schedules.nslcleaders.org/7806ca6d7c2ff9267887f4b97f83ec4ff4deb98b/CA2022/2811',
    },
    {
        ID: 2875,
        Program: 'Psychology & Neuroscience',
        'Session#': 1,
        Location: 'UC Berkeley',
        Code: 'PSYC',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '2139c7a78bb6f44c881e53a09e2c9656ba7ad908',
        URL: 'https://schedules.nslcleaders.org/2139c7a78bb6f44c881e53a09e2c9656ba7ad908/CA2022/2875',
    },
    {
        ID: 2876,
        Program: 'Psychology & Neuroscience',
        'Session#': 2,
        Location: 'UC Berkeley',
        Code: 'PSYC',
        Loc: 'CA',
        Year: 2022,
        'Start Date': '7/6/2022',
        'End Date': '7/14/2022',
        Blog: 'berkeley.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'af6e1ca4ad6c32839745213b721023935c822220',
        URL: 'https://schedules.nslcleaders.org/af6e1ca4ad6c32839745213b721023935c822220/CA2022/2876',
    },
    {
        ID: 2796,
        Program: 'Film Intensive',
        'Session#': 1,
        Location: 'University of California - UCLA',
        Code: 'FINT',
        Loc: 'UCLA',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/10/2022',
        Blog: 'ucla.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '360daaf0ed6825c4c90f7d59239771e899e69e55',
        URL: 'https://schedules.nslcleaders.org/360daaf0ed6825c4c90f7d59239771e899e69e55/UCLA2022/2796',
    },
    {
        ID: 2801,
        Program: 'Game Design',
        'Session#': 1,
        Location: 'University of California - UCLA',
        Code: 'GAME',
        Loc: 'UCLA',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'ucla.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '256211f914917899ebf22b64e2c40e7f48fc8d9e',
        URL: 'https://schedules.nslcleaders.org/256211f914917899ebf22b64e2c40e7f48fc8d9e/UCLA2022/2801',
    },
    {
        ID: 2802,
        Program: 'Game Design',
        'Session#': 2,
        Location: 'University of California - UCLA',
        Code: 'GAME',
        Loc: 'UCLA',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'ucla.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '69a0711886f735da0e5660451857a2dd678a12a0',
        URL: 'https://schedules.nslcleaders.org/69a0711886f735da0e5660451857a2dd678a12a0/UCLA2022/2802',
    },
    {
        ID: 2825,
        Program: 'Medicine & Health Care',
        'Session#': 1,
        Location: 'University of California - UCLA',
        Code: 'HEAL',
        Loc: 'UCLA',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'ucla.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'ea62322a904b05195b419f3a47f2d8a776f06e62',
        URL: 'https://schedules.nslcleaders.org/ea62322a904b05195b419f3a47f2d8a776f06e62/UCLA2022/2825',
    },
    {
        ID: 2826,
        Program: 'Medicine & Health Care',
        'Session#': 2,
        Location: 'University of California - UCLA',
        Code: 'HEAL',
        Loc: 'UCLA',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'ucla.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'd71385f0de7e8bb50145fd0b1ca07cdc7d767e96',
        URL: 'https://schedules.nslcleaders.org/d71385f0de7e8bb50145fd0b1ca07cdc7d767e96/UCLA2022/2826',
    },
    {
        ID: 2849,
        Program: 'Marine Biology',
        'Session#': 1,
        Location: 'University of San Diego',
        Code: 'MBIO',
        Loc: 'USD',
        Year: 2022,
        'Start Date': '6/13/2022',
        'End Date': '6/21/2022',
        Blog: 'usd.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '986cd62071a2e6a41ef7e61bd1957dc9d8ff3c77',
        URL: 'https://schedules.nslcleaders.org/986cd62071a2e6a41ef7e61bd1957dc9d8ff3c77/USD2022/2849',
    },
    {
        ID: 2850,
        Program: 'Marine Biology',
        'Session#': 2,
        Location: 'University of San Diego',
        Code: 'MBIO',
        Loc: 'USD',
        Year: 2022,
        'Start Date': '6/25/2022',
        'End Date': '7/3/2022',
        Blog: 'usd.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'fed611a1d138b8a056087dfd52397bff6f4edb85',
        URL: 'https://schedules.nslcleaders.org/fed611a1d138b8a056087dfd52397bff6f4edb85/USD2022/2850',
    },
    {
        ID: 3894,
        Program: 'Marine Biology',
        'Session#': 3,
        Location: 'University of San Diego',
        Code: 'MBIO',
        Loc: 'USD',
        Year: 2022,
        'Start Date': '7/7/2022',
        'End Date': '7/15/2022',
        Blog: 'usd.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '9d005ccb223267892b36b7069370f70b7c1c3be3',
        URL: 'https://schedules.nslcleaders.org/9d005ccb223267892b36b7069370f70b7c1c3be3/USD2022/3894',
    },
    {
        ID: 2847,
        Program: 'Mastering Leadership',
        'Session#': 1,
        Location: 'University of San Diego',
        Code: 'MAST',
        Loc: 'USD',
        Year: 2022,
        'Start Date': '7/13/2022',
        'End Date': '7/18/2022',
        Blog: 'usd.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '314bb33e4bd69c1b488b7ac93b903bd3b7dcdaec',
        URL: 'https://schedules.nslcleaders.org/314bb33e4bd69c1b488b7ac93b903bd3b7dcdaec/USD2022/2847',
    },
    {
        ID: 2848,
        Program: 'Mastering Leadership',
        'Session#': 2,
        Location: 'University of San Diego',
        Code: 'MAST',
        Loc: 'USD',
        Year: 2022,
        'Start Date': '07/22/2022',
        'End Date': '07/27/2022',
        Blog: 'usd.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '05ced24300df14ac9377ca95c7dfc630f1b1ae03',
        URL: 'https://schedules.nslcleaders.org/05ced24300df14ac9377ca95c7dfc630f1b1ae03/USD2022/2848',
    },
    {
        ID: 2827,
        Program: 'Medicine & Health Care',
        'Session#': 1,
        Location: 'University of San Diego',
        Code: 'HEAL',
        Loc: 'USD',
        Year: 2022,
        'Start Date': '6/13/2022',
        'End Date': '6/21/2022',
        Blog: 'usd.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'faa7026f23baa5cfcc9ab5feff7e4f08fdc690c4',
        URL: 'https://schedules.nslcleaders.org/faa7026f23baa5cfcc9ab5feff7e4f08fdc690c4/USD2022/2827',
    },
    {
        ID: 2828,
        Program: 'Medicine & Health Care',
        'Session#': 2,
        Location: 'University of San Diego',
        Code: 'HEAL',
        Loc: 'USD',
        Year: 2022,
        'Start Date': '6/25/2022',
        'End Date': '7/3/2022',
        Blog: 'usd.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '82dec6e839dfad1e5157c91f557e9bb0d6411106',
        URL: 'https://schedules.nslcleaders.org/82dec6e839dfad1e5157c91f557e9bb0d6411106/USD2022/2828',
    },
    {
        ID: 2829,
        Program: 'Medicine & Health Care',
        'Session#': 3,
        Location: 'University of San Diego',
        Code: 'HEAL',
        Loc: 'USD',
        Year: 2022,
        'Start Date': '7/7/2022',
        'End Date': '7/15/2022',
        Blog: 'usd.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'a3e61c281c5ae6449db4201e776ca8bb574df935',
        URL: 'https://schedules.nslcleaders.org/a3e61c281c5ae6449db4201e776ca8bb574df935/USD2022/2829',
    },
    {
        ID: 2830,
        Program: 'Medicine & Health Care',
        'Session#': 4,
        Location: 'University of San Diego',
        Code: 'HEAL',
        Loc: 'USD',
        Year: 2022,
        'Start Date': '7/19/2022',
        'End Date': '7/27/2022',
        Blog: 'usd.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'a0b123f6a5926d8f1267dd3c390c06352eba0e42',
        URL: 'https://schedules.nslcleaders.org/a0b123f6a5926d8f1267dd3c390c06352eba0e42/USD2022/2830',
    },
    {
        ID: 3941,
        Program: 'Middle School',
        'Session#': 2,
        Location: 'University of San Diego',
        Code: 'JNSLC',
        Loc: 'USD',
        Year: 2022,
        'Start Date': '06/25/2022',
        'End Date': '06/30/2022',
        Blog: 'usd.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '267feae2153faf16e8a3499b27f35a4ceef6fd8a',
        URL: 'https://schedules.nslcleaders.org/267feae2153faf16e8a3499b27f35a4ceef6fd8a/USD2022/3941',
    },
    {
        ID: 2788,
        Program: 'Engineering',
        'Session#': 1,
        Location: 'University of Texas - Austin',
        Code: 'ENGN',
        Loc: 'UTA',
        Year: 2022,
        'Start Date': '7/10/2022',
        'End Date': '7/18/2022',
        Blog: 'texas.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '862dc631f62f7cf0030035a941aff511d9cef456',
        URL: 'https://schedules.nslcleaders.org/862dc631f62f7cf0030035a941aff511d9cef456/UTA2022/2788',
    },
    {
        ID: 2789,
        Program: 'Engineering',
        'Session#': 2,
        Location: 'University of Texas - Austin',
        Code: 'ENGN',
        Loc: 'UTA',
        Year: 2022,
        'Start Date': '7/22/2022',
        'End Date': '7/30/2022',
        Blog: 'texas.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'a9b50006780c65bc8f1790385f85b869403f5985',
        URL: 'https://schedules.nslcleaders.org/a9b50006780c65bc8f1790385f85b869403f5985/UTA2022/2789',
    },
    {
        ID: 2823,
        Program: 'Medicine & Health Care',
        'Session#': 1,
        Location: 'University of Texas - Austin',
        Code: 'HEAL',
        Loc: 'UTA',
        Year: 2022,
        'Start Date': '7/10/2022',
        'End Date': '7/18/2022',
        Blog: 'texas.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '5f585e98844c6fbef11573fec4044cd60e3b4c5a',
        URL: 'https://schedules.nslcleaders.org/5f585e98844c6fbef11573fec4044cd60e3b4c5a/UTA2022/2823',
    },
    {
        ID: 2824,
        Program: 'Medicine & Health Care',
        'Session#': 2,
        Location: 'University of Texas - Austin',
        Code: 'HEAL',
        Loc: 'UTA',
        Year: 2022,
        'Start Date': '7/22/2022',
        'End Date': '7/30/2022',
        Blog: 'texas.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'c22c74a0189ed6c34d0595dfcb527b6e9d04a021',
        URL: 'https://schedules.nslcleaders.org/c22c74a0189ed6c34d0595dfcb527b6e9d04a021/UTA2022/2824',
    },
    {
        ID: 2767,
        Program: 'Business & Entrepreneurship',
        'Session#': 1,
        Location: 'Yale University',
        Code: 'BUSI',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '7d93897fe022bf03d3e30812e1997492c0810bd4',
        URL: 'https://schedules.nslcleaders.org/7d93897fe022bf03d3e30812e1997492c0810bd4/YU2022/2767',
    },
    {
        ID: 2768,
        Program: 'Business & Entrepreneurship',
        'Session#': 2,
        Location: 'Yale University',
        Code: 'BUSI',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '7/6/2022',
        'End Date': '7/14/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'a78f27dbdc5e4ad535837804b35d83e0b5f05190',
        URL: 'https://schedules.nslcleaders.org/a78f27dbdc5e4ad535837804b35d83e0b5f05190/YU2022/2768',
    },
    {
        ID: 2769,
        Program: 'Business & Entrepreneurship',
        'Session#': 3,
        Location: 'Yale University',
        Code: 'BUSI',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '07aa22294f9b58c99d569717041faf52d4761823',
        URL: 'https://schedules.nslcleaders.org/07aa22294f9b58c99d569717041faf52d4761823/YU2022/2769',
    },
    {
        ID: 2790,
        Program: 'Engineering',
        'Session#': 1,
        Location: 'Yale University',
        Code: 'ENGN',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '4727e9a2ee4bfc123b69c6f16394ebf5bf785493',
        URL: 'https://schedules.nslcleaders.org/4727e9a2ee4bfc123b69c6f16394ebf5bf785493/YU2022/2790',
    },
    {
        ID: 2791,
        Program: 'Engineering',
        'Session#': 2,
        Location: 'Yale University',
        Code: 'ENGN',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '07/06/2022',
        'End Date': '07/14/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'e9c3f99134d6acbc5ec69a6e4bd79d4029ff67fc',
        URL: 'https://schedules.nslcleaders.org/e9c3f99134d6acbc5ec69a6e4bd79d4029ff67fc/YU2022/2791',
    },
    {
        ID: 2792,
        Program: 'Engineering',
        'Session#': 3,
        Location: 'Yale University',
        Code: 'ENGN',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '07/17/2022',
        'End Date': '07/25/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '779f44aff70c33d6dcee7d5b915bb3b8f16017b6',
        URL: 'https://schedules.nslcleaders.org/779f44aff70c33d6dcee7d5b915bb3b8f16017b6/YU2022/2792',
    },
    {
        ID: 2793,
        Program: 'Environmental Science & Sustainability',
        'Session#': 1,
        Location: 'Yale University',
        Code: 'ENVI',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '07ed00299de679c444bc6ee4949ac5d5bc002f39',
        URL: 'https://schedules.nslcleaders.org/07ed00299de679c444bc6ee4949ac5d5bc002f39/YU2022/2793',
    },
    {
        ID: 2794,
        Program: 'Environmental Science & Sustainability',
        'Session#': 2,
        Location: 'Yale University',
        Code: 'ENVI',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '7/6/2022',
        'End Date': '7/14/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'd2d0024185299a95aaf248de54d39798df582b3c',
        URL: 'https://schedules.nslcleaders.org/d2d0024185299a95aaf248de54d39798df582b3c/YU2022/2794',
    },
    {
        ID: 2795,
        Program: 'Environmental Science & Sustainability',
        'Session#': 3,
        Location: 'Yale University',
        Code: 'ENVI',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '771e015f547c481aca4fcab116b3778e961c8e14',
        URL: 'https://schedules.nslcleaders.org/771e015f547c481aca4fcab116b3778e961c8e14/YU2022/2795',
    },
    {
        ID: 2866,
        Program: 'Law & Advocacy',
        'Session#': 1,
        Location: 'Yale University',
        Code: 'LAWA',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '6/24/2022',
        'End Date': '7/2/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '11e7847e2dba062fe140bb437afa196eea4658c0',
        URL: 'https://schedules.nslcleaders.org/11e7847e2dba062fe140bb437afa196eea4658c0/YU2022/2866',
    },
    {
        ID: 2867,
        Program: 'Law & Advocacy',
        'Session#': 2,
        Location: 'Yale University',
        Code: 'LAWA',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '7/6/2022',
        'End Date': '7/14/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '16243528167a2e7bac218e16a5e01c4cd664fede',
        URL: 'https://schedules.nslcleaders.org/16243528167a2e7bac218e16a5e01c4cd664fede/YU2022/2867',
    },
    {
        ID: 2868,
        Program: 'Law & Advocacy',
        'Session#': 3,
        Location: 'Yale University',
        Code: 'LAWA',
        Loc: 'YU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'yale.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '1fda1dcce5df75d9f95146fa3bd5713e83400da6',
        URL: 'https://schedules.nslcleaders.org/1fda1dcce5df75d9f95146fa3bd5713e83400da6/YU2022/2868',
    },
    {
        ID: 3982,
        Program: 'Forensic Science B',
        'Session#': 1,
        Location: 'American University',
        Code: 'FSCI',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/11/2022',
        'End Date': '6/19/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '279ac7331227a2f46dff719cdebe81e8c4840114',
        URL: 'https://schedules.nslcleaders.org/1fda1dcce5df75d9f95146fa3bd5713e83400da6/YU2022/2868',
    },
    {
        ID: 3984,
        Program: 'Forensic Science B',
        'Session#': 2,
        Location: 'American University',
        Code: 'FSCI',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '6/23/2022',
        'End Date': '7/1/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '556f31424749cf9652900f626fbec0b8a7100928',
        URL: 'https://schedules.nslcleaders.org/1fda1dcce5df75d9f95146fa3bd5713e83400da6/YU2022/2868',
    },
    {
        ID: 3973,
        Program: 'Forensic Science B',
        'Session#': 3,
        Location: 'American University',
        Code: 'FSCI',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/5/2022',
        'End Date': '7/13/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '59772bdf7f145800d9d77837f0497f78417cc4af',
        URL: 'https://schedules.nslcleaders.org/1fda1dcce5df75d9f95146fa3bd5713e83400da6/YU2022/2868',
    },
    {
        ID: 3975,
        Program: 'Forensic Science B',
        'Session#': 4,
        Location: 'American University',
        Code: 'FSCI',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/17/2022',
        'End Date': '7/25/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': 'd06b512dd7841a216250e3655c21aa3b8481dc4b',
        URL: 'https://schedules.nslcleaders.org/d06b512dd7841a216250e3655c21aa3b8481dc4b/AU2022/3975',
    },
    {
        ID: 4006,
        Program: 'Middle School B',
        'Session#': 1,
        Location: 'American University',
        Code: 'JNSLC',
        Loc: 'AU',
        Year: 2022,
        'Start Date': '7/05/2022',
        'End Date': '7/10/2022',
        Blog: 'american.nslcleaders.org',
        Status: 'Active',
        'Hash for Session Meta': '416d9c8c3fefe5529f86b55c7fbf116baec1a1a2',
        URL: 'https://schedules.nslcleaders.org/416d9c8c3fefe5529f86b55c7fbf116baec1a1a2/AU2022/4006',
    },
];

export default sessions2022;
