const sessions2020 = {
  "data": [
    ["ID", "Program", "Session#", "Location", "Year", "Start Date", "End Date", "Blog", "Status"],
    [
      2436,
      "Acting Intensive",
      1,
      "Yale University",
      2020,
      "7/21/2020T00:00:00",
      "8/7/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2287,
      "Aerospace",
      1,
      "American University",
      2020,
      "6/12/2020T00:00:00",
      "6/20/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2288,
      "Aerospace",
      2,
      "American University",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2289,
      "Aerospace",
      3,
      "American University",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2290,
      "Aerospace",
      4,
      "Georgetown University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2419,
      "Aerospace",
      5,
      "Georgetown University",
      2020,
      "7/29/2020T00:00:00",
      "8/6/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2420,
      "Architecture",
      1,
      "Northwestern University",
      2020,
      "7/19/2020T00:00:00",
      "7/27/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2421,
      "Architecture",
      2,
      "Northwestern University",
      2020,
      "7/31/2020T00:00:00",
      "8/8/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2291,
      "Biotechnology",
      1,
      "American University",
      2020,
      "6/12/2020T00:00:00",
      "6/20/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2292,
      "Biotechnology",
      2,
      "American University",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2422,
      "Biotechnology",
      1,
      "Northwestern University",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2423,
      "Biotechnology",
      2,
      "Northwestern University",
      2020,
      "7/7/2020T00:00:00",
      "7/15/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2375,
      "Biotechnology",
      1,
      "UC Berkeley",
      2020,
      "7/7/2020T00:00:00",
      "7/15/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2382,
      "Biotechnology",
      2,
      "UC Berkeley",
      2020,
      "7/20/2020T00:00:00",
      "7/28/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2434,
      "Business & Emerging Technologies",
      1,
      "Northwestern University",
      2020,
      "7/19/2020T00:00:00",
      "7/27/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2434,
      "Business & Emerging Technologies",
      1,
      "Northwestern University",
      2020,
      "7/19/2020T00:00:00",
      "7/27/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2435,
      "Business & Emerging Technologies",
      2,
      "Northwestern University",
      2020,
      "7/31/2020T00:00:00",
      "8/8/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2435,
      "Business & Emerging Technologies",
      2,
      "Northwestern University",
      2020,
      "7/31/2020T00:00:00",
      "8/8/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2327,
      "Business & Entrepreneurship",
      1,
      "Fordham University",
      2020,
      "7/6/2020T00:00:00",
      "7/14/2020T00:00:00",
      "fordham.nslcleaders.org/",
      "Active"
    ],
    [
      2328,
      "Business & Entrepreneurship",
      2,
      "Fordham University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "fordham.nslcleaders.org/",
      "Active"
    ],
    [
      2424,
      "Business & Entrepreneurship",
      1,
      "Northwestern University",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2425,
      "Business & Entrepreneurship",
      2,
      "Northwestern University",
      2020,
      "7/7/2020T00:00:00",
      "7/15/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2425,
      "Business & Entrepreneurship",
      2,
      "Northwestern University",
      2020,
      "7/7/2020T00:00:00",
      "7/15/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2376,
      "Business & Entrepreneurship",
      1,
      "UC Berkeley",
      2020,
      "6/13/2020T00:00:00",
      "6/21/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2383,
      "Business & Entrepreneurship",
      2,
      "UC Berkeley",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2384,
      "Business & Entrepreneurship",
      3,
      "UC Berkeley",
      2020,
      "7/7/2020T00:00:00",
      "7/15/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2385,
      "Business & Entrepreneurship",
      4,
      "UC Berkeley",
      2020,
      "7/20/2020T00:00:00",
      "7/28/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2441,
      "Business & Entrepreneurship",
      1,
      "Yale University",
      2020,
      "6/24/2020T00:00:00",
      "7/2/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2442,
      "Business & Entrepreneurship",
      2,
      "Yale University",
      2020,
      "7/6/2020T00:00:00",
      "7/14/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2443,
      "Business & Entrepreneurship",
      3,
      "Yale University",
      2020,
      "7/18/2020T00:00:00",
      "7/26/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2444,
      "Business & Entrepreneurship",
      4,
      "Yale University",
      2020,
      "7/30/2020T00:00:00",
      "8/7/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2359,
      "Business Intensive",
      1,
      "Georgetown University",
      2020,
      "7/17/2020T00:00:00",
      "8/3/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2296,
      "Cybersecurity",
      1,
      "American University",
      2020,
      "6/12/2020T00:00:00",
      "6/20/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2297,
      "Cybersecurity",
      2,
      "American University",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2298,
      "Cybersecurity",
      3,
      "American University",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2299,
      "Cybersecurity",
      4,
      "American University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2332,
      "Data Science",
      1,
      "Georgia Tech",
      2020,
      "6/13/2020T00:00:00",
      "6/21/2020T00:00:00",
      "georgiatech.nslcleaders.org",
      "Active"
    ],
    [
      2333,
      "Data Science",
      2,
      "Georgia Tech",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "georgiatech.nslcleaders.org",
      "Active"
    ],
    [
      2300,
      "Engineering",
      1,
      "American University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2301,
      "Engineering",
      2,
      "American University",
      2020,
      "7/29/2020T00:00:00",
      "8/6/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2330,
      "Engineering",
      1,
      "Georgia Tech",
      2020,
      "6/13/2020T00:00:00",
      "6/21/2020T00:00:00",
      "georgiatech.nslcleaders.org",
      "Active"
    ],
    [
      2331,
      "Engineering",
      2,
      "Georgia Tech",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "georgiatech.nslcleaders.org",
      "Active"
    ],
    [
      2426,
      "Engineering",
      1,
      "Northwestern University",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2427,
      "Engineering",
      2,
      "Northwestern University",
      2020,
      "7/7/2020T00:00:00",
      "7/15/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2334,
      "Engineering",
      1,
      "Rice University",
      2020,
      "7/11/2020T00:00:00",
      "7/19/2020T00:00:00",
      "rice.nslcleaders.org",
      "Active"
    ],
    [
      2335,
      "Engineering",
      2,
      "Rice University",
      2020,
      "7/23/2020T00:00:00",
      "7/31/2020T00:00:00",
      "rice.nslcleaders.org",
      "Active"
    ],
    [
      2379,
      "Engineering",
      1,
      "UC Berkeley",
      2020,
      "6/13/2020T00:00:00",
      "6/21/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2386,
      "Engineering",
      2,
      "UC Berkeley",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2445,
      "Engineering",
      1,
      "Yale University",
      2020,
      "6/24/2020T00:00:00",
      "7/2/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2446,
      "Engineering",
      2,
      "Yale University",
      2020,
      "7/6/2020T00:00:00",
      "7/14/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2447,
      "Engineering",
      3,
      "Yale University",
      2020,
      "7/18/2020T00:00:00",
      "7/26/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2448,
      "Engineering",
      4,
      "Yale University",
      2020,
      "7/30/2020T00:00:00",
      "8/7/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2378,
      "Engineering Intensive",
      1,
      "UC Berkeley",
      2020,
      "7/11/2020T00:00:00",
      "7/28/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2378,
      "Engineering Intensive",
      1,
      "UC Berkeley",
      2020,
      "7/11/2020T00:00:00",
      "7/28/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2403,
      "Entertainment Management",
      1,
      "University of California - UCLA",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "ucla.nslcleaders.org",
      "Active"
    ],
    [
      2404,
      "Entertainment Management",
      2,
      "University of California - UCLA",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "ucla.nslcleaders.org",
      "Active"
    ],
    [
      2342,
      "Environmental Science & Sustainability",
      1,
      "University of Colorado",
      2020,
      "6/13/2020T00:00:00",
      "6/21/2020T00:00:00",
      "colorado.nslcleaders.org",
      "Active"
    ],
    [
      2343,
      "Environmental Science & Sustainability",
      2,
      "University of Colorado",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "colorado.nslcleaders.org",
      "Active"
    ],
    [
      2293,
      "Film, Journalism & Media Arts",
      1,
      "American University",
      2020,
      "6/12/2020T00:00:00",
      "6/20/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2294,
      "Film, Journalism & Media Arts",
      2,
      "American University",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2295,
      "Film, Journalism & Media Arts",
      3,
      "American University",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2377,
      "Film, Journalism & Media Arts",
      1,
      "UC Berkeley",
      2020,
      "7/20/2020T00:00:00",
      "7/28/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2377,
      "Film, Journalism & Media Arts",
      1,
      "UC Berkeley",
      2020,
      "7/20/2020T00:00:00",
      "7/28/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2302,
      "Forensic Science",
      1,
      "American University",
      2020,
      "6/12/2020T00:00:00",
      "6/20/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2303,
      "Forensic Science",
      2,
      "American University",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2304,
      "Forensic Science",
      3,
      "American University",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2305,
      "Forensic Science",
      4,
      "American University",
      2020,
      "7/17/2020T00:00:00",
      "7/18/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2306,
      "Forensic Science",
      5,
      "American University",
      2020,
      "7/29/2020T00:00:00",
      "8/6/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2338,
      "Game Design",
      1,
      "University of Washington",
      2020,
      "7/18/2020T00:00:00",
      "7/26/2020T00:00:00",
      "uw.nslcleaders.org",
      "Active"
    ],
    [
      2339,
      "Game Design",
      2,
      "University of Washington",
      2020,
      "7/30/2020T00:00:00",
      "8/7/2020T00:00:00",
      "uw.nslcleaders.org",
      "Active"
    ],
    [
      2312,
      "Intelligence & National Security",
      1,
      "American University",
      2020,
      "7/5/2020T00:00:00",
      "7/10/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2313,
      "Intelligence & National Security",
      2,
      "American University",
      2020,
      "7/13/2020T00:00:00",
      "7/18/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2314,
      "Intelligence & National Security",
      3,
      "American University",
      2020,
      "7/21/2020T00:00:00",
      "7/26/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2409,
      "International Business",
      1,
      "McGill University",
      2020,
      "7/14/2020T00:00:00",
      "7/22/2020T00:00:00",
      "mcgill.nslcleaders.org",
      "Active"
    ],
    [
      2410,
      "International Business",
      2,
      "McGill University",
      2020,
      "7/26/2020T00:00:00",
      "8/3/2020T00:00:00",
      "mcgill.nslcleaders.org",
      "Active"
    ],
    [
      2393,
      "International Business in Singapore",
      1,
      "NUS (Singapore)",
      2020,
      "6/11/2020T00:00:00",
      "6/20/2020T00:00:00",
      "singapore.nslcleaders.org",
      "Active"
    ],
    [
      2394,
      "International Business in Singapore",
      2,
      "NUS (Singapore)",
      2020,
      "6/24/2020T00:00:00",
      "7/3/2020T00:00:00",
      "singapore.nslcleaders.org",
      "Active"
    ],
    [
      2360,
      "International Diplomacy",
      1,
      "Georgetown University",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2361,
      "International Diplomacy",
      2,
      "Georgetown University",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2362,
      "International Diplomacy",
      3,
      "Georgetown University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2363,
      "International Diplomacy",
      4,
      "Georgetown University",
      2020,
      "7/29/2020T00:00:00",
      "8/6/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2364,
      "Law & Advocacy",
      1,
      "Georgetown University",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2365,
      "Law & Advocacy",
      2,
      "Georgetown University",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2366,
      "Law & Advocacy",
      3,
      "Georgetown University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2367,
      "Law & Advocacy",
      4,
      "Georgetown University",
      2020,
      "7/29/2020T00:00:00",
      "8/6/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ],
    [
      2449,
      "Law & Advocacy",
      1,
      "Yale University",
      2020,
      "6/24/2020T00:00:00",
      "7/2/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2450,
      "Law & Advocacy",
      2,
      "Yale University",
      2020,
      "7/6/2020T00:00:00",
      "7/14/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2451,
      "Law & Advocacy",
      3,
      "Yale University",
      2020,
      "7/18/2020T00:00:00",
      "7/26/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2452,
      "Law & Advocacy",
      4,
      "Yale University",
      2020,
      "7/30/2020T00:00:00",
      "8/7/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2399,
      "Marine Biology",
      1,
      "University of Hawaii",
      2020,
      "7/14/2020T00:00:00",
      "7/22/2020T00:00:00",
      "hawaii.nslcleaders.org",
      "Active"
    ],
    [
      2400,
      "Marine Biology",
      2,
      "University of Hawaii",
      2020,
      "7/26/2020T00:00:00",
      "8/3/2020T00:00:00",
      "hawaii.nslcleaders.org",
      "Active"
    ],
    [
      2397,
      "Marine Biology",
      1,
      "University of Miami",
      2020,
      "7/11/2020T00:00:00",
      "7/19/2020T00:00:00",
      "miami.nslcleaders.org",
      "Active"
    ],
    [
      2398,
      "Marine Biology",
      2,
      "University of Miami",
      2020,
      "7/23/2020T00:00:00",
      "7/31/2020T00:00:00",
      "miami.nslcleaders.org",
      "Active"
    ],
    [
      2315,
      "Mastering Leadership",
      1,
      "American University",
      2020,
      "7/5/2020T00:00:00",
      "7/10/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2316,
      "Mastering Leadership",
      2,
      "American University",
      2020,
      "7/13/2020T00:00:00",
      "7/18/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2317,
      "Mastering Leadership",
      3,
      "American University",
      2020,
      "7/21/2020T00:00:00",
      "7/26/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2355,
      "Mastering Leadership",
      1,
      "Vanderbilt University",
      2020,
      "6/17/2020T00:00:00",
      "6/22/2020T00:00:00",
      "vanderbilt.nslcleaders.org",
      "Active"
    ],
    [
      2356,
      "Mastering Leadership",
      2,
      "Vanderbilt University",
      2020,
      "6/25/2020T00:00:00",
      "6/30/2020T00:00:00",
      "vanderbilt.nslcleaders.org",
      "Active"
    ],
    [
      2307,
      "Medicine & Health Care",
      1,
      "American University",
      2020,
      "6/12/2020T00:00:00",
      "6/20/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2308,
      "Medicine & Health Care",
      2,
      "American University",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2309,
      "Medicine & Health Care",
      3,
      "American University",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2310,
      "Medicine & Health Care",
      4,
      "American University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2311,
      "Medicine & Health Care",
      5,
      "American University",
      2020,
      "7/29/2020T00:00:00",
      "8/6/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2455,
      "Medicine & Health Care",
      1,
      "Harvard Medical School",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "boston.nslcleaders.org",
      "Active"
    ],
    [
      2456,
      "Medicine & Health Care",
      2,
      "Harvard Medical School",
      2020,
      "7/7/2020T00:00:00",
      "7/15/2020T00:00:00",
      "boston.nslcleaders.org",
      "Active"
    ],
    [
      2457,
      "Medicine & Health Care",
      3,
      "Harvard Medical School",
      2020,
      "7/19/2020T00:00:00",
      "7/27/2020T00:00:00",
      "boston.nslcleaders.org",
      "Active"
    ],
    [
      2458,
      "Medicine & Health Care",
      4,
      "Harvard Medical School",
      2020,
      "7/31/2020T00:00:00",
      "8/8/2020T00:00:00",
      "boston.nslcleaders.org",
      "Active"
    ],
    [
      2407,
      "Medicine & Health Care",
      1,
      "McGill University",
      2020,
      "7/14/2020T00:00:00",
      "7/22/2020T00:00:00",
      "mcgill.nslcleaders.org",
      "Active"
    ],
    [
      2408,
      "Medicine & Health Care",
      2,
      "McGill University",
      2020,
      "7/26/2020T00:00:00",
      "8/3/2020T00:00:00",
      "mcgill.nslcleaders.org",
      "Active"
    ],
    [
      2428,
      "Medicine & Health Care",
      1,
      "Northwestern University",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2429,
      "Medicine & Health Care",
      2,
      "Northwestern University",
      2020,
      "7/7/2020T00:00:00",
      "7/15/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2430,
      "Medicine & Health Care",
      3,
      "Northwestern University",
      2020,
      "7/19/2020T00:00:00",
      "7/27/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2431,
      "Medicine & Health Care",
      4,
      "Northwestern University",
      2020,
      "7/31/2020T00:00:00",
      "8/8/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2392,
      "Medicine & Health Care",
      1,
      "NUS (Singapore)",
      2020,
      "6/11/2020T00:00:00",
      "6/20/2020T00:00:00",
      "singapore.nslcleaders.org",
      "Active"
    ],
    [
      2336,
      "Medicine & Health Care",
      1,
      "Rice University",
      2020,
      "7/11/2020T00:00:00",
      "7/19/2020T00:00:00",
      "rice.nslcleaders.org",
      "Active"
    ],
    [
      2337,
      "Medicine & Health Care",
      2,
      "Rice University",
      2020,
      "7/23/2020T00:00:00",
      "7/31/2020T00:00:00",
      "rice.nslcleaders.org",
      "Active"
    ],
    [
      2380,
      "Medicine & Health Care",
      1,
      "UC Berkeley",
      2020,
      "6/13/2020T00:00:00",
      "6/21/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2387,
      "Medicine & Health Care",
      2,
      "UC Berkeley",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2388,
      "Medicine & Health Care",
      3,
      "UC Berkeley",
      2020,
      "7/7/2020T00:00:00",
      "7/15/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2389,
      "Medicine & Health Care",
      4,
      "UC Berkeley",
      2020,
      "7/20/2020T00:00:00",
      "7/28/2020T00:00:00",
      "berkeley.nslcleaders.org",
      "Active"
    ],
    [
      2405,
      "Medicine & Health Care",
      1,
      "University of California - UCLA",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "ucla.nslcleaders.org",
      "Active"
    ],
    [
      2406,
      "Medicine & Health Care",
      2,
      "University of California - UCLA",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "ucla.nslcleaders.org",
      "Active"
    ],
    [
      2344,
      "Medicine & Health Care",
      1,
      "University of Colorado",
      2020,
      "6/13/2020T00:00:00",
      "6/21/2020T00:00:00",
      "colorado.nslcleaders.org",
      "Active"
    ],
    [
      2345,
      "Medicine & Health Care",
      2,
      "University of Colorado",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "colorado.nslcleaders.org",
      "Active"
    ],
    [
      2401,
      "Medicine & Health Care",
      1,
      "University of Hawaii",
      2020,
      "7/14/2020T00:00:00",
      "7/22/2020T00:00:00",
      "hawaii.nslcleaders.org",
      "Active"
    ],
    [
      2402,
      "Medicine & Health Care",
      2,
      "University of Hawaii",
      2020,
      "7/26/2020T00:00:00",
      "8/3/2020T00:00:00",
      "hawaii.nslcleaders.org",
      "Active"
    ],
    [
      2395,
      "Medicine & Health Care",
      1,
      "University of Miami",
      2020,
      "7/11/2020T00:00:00",
      "7/19/2020T00:00:00",
      "miami.nslcleaders.org",
      "Active"
    ],
    [
      2396,
      "Medicine & Health Care",
      1,
      "University of Miami",
      2020,
      "7/23/2020T00:00:00",
      "7/31/2020T00:00:00",
      "miami.nslcleaders.org",
      "Active"
    ],
    [
      2340,
      "Medicine & Health Care",
      1,
      "University of Washington",
      2020,
      "7/18/2020T00:00:00",
      "7/26/2020T00:00:00",
      "uw.nslcleaders.org",
      "Active"
    ],
    [
      2341,
      "Medicine & Health Care",
      2,
      "University of Washington",
      2020,
      "7/30/2020T00:00:00",
      "8/7/2020T00:00:00",
      "uw.nslcleaders.org",
      "Active"
    ],
    [
      2351,
      "Medicine & Health Care",
      1,
      "Vanderbilt University",
      2020,
      "6/14/2020T00:00:00",
      "6/22/2020T00:00:00",
      "vanderbilt.nslcleaders.org",
      "Active"
    ],
    [
      2352,
      "Medicine & Health Care",
      2,
      "Vanderbilt University",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "vanderbilt.nslcleaders.org",
      "Active"
    ],
    [
      2325,
      "Middle School",
      1,
      "American University",
      2020,
      "7/5/2020T00:00:00",
      "7/10/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2326,
      "Middle School",
      2,
      "American University",
      2020,
      "7/13/2020T00:00:00",
      "7/18/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2368,
      "Nursing",
      1,
      "American University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2369,
      "Nursing",
      2,
      "American University",
      2020,
      "7/29/2020T00:00:00",
      "8/6/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2453,
      "Nursing",
      1,
      "Yale University",
      2020,
      "6/24/2020T00:00:00",
      "7/2/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2454,
      "Nursing",
      2,
      "Yale University",
      2020,
      "7/6/2020T00:00:00",
      "7/14/2020T00:00:00",
      "yale.nslcleaders.org",
      "Active"
    ],
    [
      2318,
      "Political Action & Public Policy",
      1,
      "American University",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2319,
      "Political Action & Public Policy",
      2,
      "American University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2320,
      "Psychology & Neuroscience",
      1,
      "American University",
      2020,
      "6/12/2020T00:00:00",
      "6/20/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2321,
      "Psychology & Neuroscience",
      2,
      "American University",
      2020,
      "6/23/2020T00:00:00",
      "7/1/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2322,
      "Psychology & Neuroscience",
      3,
      "American University",
      2020,
      "7/5/2020T00:00:00",
      "7/13/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2323,
      "Psychology & Neuroscience",
      4,
      "American University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2324,
      "Psychology & Neuroscience",
      5,
      "American University",
      2020,
      "7/29/2020T00:00:00",
      "8/6/2020T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2459,
      "Psychology & Neuroscience",
      1,
      "Harvard Medical School",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "boston.nslcleaders.org",
      "Active"
    ],
    [
      2460,
      "Psychology & Neuroscience",
      2,
      "Harvard Medical School",
      2020,
      "7/7/2020T00:00:00",
      "7/15/2020T00:00:00",
      "boston.nslcleaders.org",
      "Active"
    ],
    [
      2461,
      "Psychology & Neuroscience",
      3,
      "Harvard Medical School",
      2020,
      "7/19/2020T00:00:00",
      "7/27/2020T00:00:00",
      "boston.nslcleaders.org",
      "Active"
    ],
    [
      2462,
      "Psychology & Neuroscience",
      4,
      "Harvard Medical School",
      2020,
      "7/31/2020T00:00:00",
      "8/8/2020T00:00:00",
      "boston.nslcleaders.org",
      "Active"
    ],
    [
      2353,
      "Psychology & Neuroscience",
      1,
      "Vanderbilt University",
      2020,
      "6/14/2020T00:00:00",
      "6/22/2020T00:00:00",
      "vanderbilt.nslcleaders.org",
      "Active"
    ],
    [
      2354,
      "Psychology & Neuroscience",
      2,
      "Vanderbilt University",
      2020,
      "6/25/2020T00:00:00",
      "7/3/2020T00:00:00",
      "vanderbilt.nslcleaders.org",
      "Active"
    ],
    [
      2432,
      "Social Impact & Community Engagement",
      1,
      "Northwestern University",
      2020,
      "7/19/2020T00:00:00",
      "7/27/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2433,
      "Social Impact & Community Engagement",
      2,
      "Northwestern University",
      2020,
      "7/31/2020T00:00:00",
      "8/8/2020T00:00:00",
      "northwestern.nslcleaders.org",
      "Active"
    ],
    [
      2329,
      "Sports Management",
      1,
      "Fordham University",
      2020,
      "7/6/2020T00:00:00",
      "7/14/2020T00:00:00",
      "fordham.nslcleaders.org/",
      "Active"
    ],
    [
      2347,
      "Sports Management",
      2,
      "Fordham University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "fordham.nslcleaders.org/",
      "Active"
    ],
    [
      2349,
      "Sports Management",
      3,
      "Fordham University",
      2020,
      "7/28/2020T00:00:00",
      "8/5/2020T00:00:00",
      "fordham.nslcleaders.org/",
      "Active"
    ],
    [
      1418,
      "Test Program",
      1,
      "American University",
      2020,
      "10/5/2019T00:00:00",
      "10/15/2019T00:00:00",
      "american.nslcleaders.org",
      "Active"
    ],
    [
      2346,
      "Theater",
      1,
      "Fordham University",
      2020,
      "7/6/2020T00:00:00",
      "7/14/2020T00:00:00",
      "fordham.nslcleaders.org/",
      "Active"
    ],
    [
      2348,
      "Theater",
      2,
      "Fordham University",
      2020,
      "7/17/2020T00:00:00",
      "7/25/2020T00:00:00",
      "fordham.nslcleaders.org/",
      "Active"
    ],
    [
      2350,
      "Theater",
      3,
      "Fordham University",
      2020,
      "7/28/2020T00:00:00",
      "8/5/2020T00:00:00",
      "fordham.nslcleaders.org/",
      "Active"
    ],
    [
      2416,
      "Veterinary Medicine",
      1,
      "Drexel/Penn Vet",
      2020,
      "7/15/2020T00:00:00",
      "7/23/2020T00:00:00",
      "penn.nslcleaders.org",
      "Active"
    ],
    [
      2417,
      "Veterinary Medicine",
      2,
      "Drexel/Penn Vet",
      2020,
      "7/27/2020T00:00:00",
      "8/4/2020T00:00:00",
      "penn.nslcleaders.org",
      "Active"
    ],
    [
      2418,
      "Veterinary Medicine",
      3,
      "Drexel/Penn Vet",
      2020,
      "8/8/2020T00:00:00",
      "8/16/2020T00:00:00",
      "penn.nslcleaders.org",
      "Active"
    ],
    [
      2463,
      "Film Intensive",
      1,
      "American University",
      2020,
      "6/23/2020T00:00:00",
      "7/10/2020T00:00:00",
      "American.nslcleaders.org",
      "Active"
    ],
    [
      2464,
      "Law Intensive",
      1,
      "Georgetown University",
      2020,
      "6/23/2020T00:00:00",
      "7/10/2020T00:00:00",
      "georgetown.nslcleaders.org",
      "Active"
    ]
  ]
};

export default sessions2020;
