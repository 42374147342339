import { zipObject } from 'lodash';
import sessions2020 from '../assets/Sessions2020';
import sessions2021 from '../assets/Sessions2021';
import sessions2022 from '../assets/Sessions2022';

const getSessions = async year => {
  if (year === '2020') {
    const [headers, ...bodyRows] = sessions2020.data;

    return bodyRows.map(event => zipObject(headers, event));
  }

  if (year === '2021') {
    return sessions2021;
  }

  if (year === '2022') {
    return sessions2022;
  }

  const request = await fetch(
    'https://mobile-api.nslcleaders.org/api/v1/sessions/5bb20c47-082c-48fe-8294-814ee36e6eb3/81086144-e30c-4829-8ea4-0ee0f9af14b5',
  );
  const [headers, ...bodyRows] = (await request.json()).data;

  return bodyRows.map(event => zipObject(headers, event));
};

export default {
  getSessions,
};
